import 'react-credit-cards/es/styles-compiled.css';

import { Col, Container, Form, InputGroup, Nav, Navbar, Row, Tab } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { NewOrder, NewPaypalOrder, WechatPay } from '../api/API';
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import React, { useEffect, useRef, useState } from 'react';
import { addCart, delCart } from './redux/action';
import { formatCVC, formatCreditCardNumber, formatExpirationDate, formatFormData } from './sections/Payment';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { AiFillAlipayCircle } from 'react-icons/ai';
import { AiFillWechat } from 'react-icons/ai';
import { BsCreditCardFill } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FaPaypal } from 'react-icons/fa';
import FontAwesome from './common/FontAwesome';
import Icofont from 'react-icofont';
import { IconContext } from 'react-icons';
import Modal from 'react-bootstrap/Modal';
import { RiArrowGoBackLine } from 'react-icons/ri';
import Typography from '@material-ui/core/Typography';
import { debounce } from './Debounce';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: '15px',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		fontWeight: 'bold',
		color: 'black',
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
}));
const Checkout = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [English, setEnglish] = useState(true);
	const dineIn = localStorage.getItem('dineIn');
	const pickUpTime = useSelector((state) => state.time);
	var ual = navigator.language;
	var baseLang = 'zh';
	baseLang = ual ? ual.substring(0, 2).toLowerCase() : 'zh';
	// localStorage.setItem('baseLang', JSON.stringify(baseLang));
	useEffect(() => {
		if (baseLang == 'zh') {
			setEnglish(false);
		}
	}, []);
	const cart = useSelector((state) => state.cart);
	const [address, setAddress] = useState(null);
	const [postcode, setPostcode] = useState(null);
	const [num, setNum] = useState('1');
	const [tableNumber, setTableNumber] = useState(
		localStorage.getItem('tableNumber') ? JSON.parse(localStorage.getItem('tableNumber')) : 0
	);
	// console.log('dine in', dineIn);
	// console.log('pickupTime', pickUpTime);
	const [tel, setTel] = useState(null);
	const [expMonth, setExpMonth] = useState(null);
	const [expYear, setExpYear] = useState(null);
	const [beizhu, setBeizhu] = useState(null);
	const [cvv, setCvv] = useState(null);
	const [cardNumber, setCardNumber] = useState(null);
	//console.log('=> cardNumber', cardNumber);
	const [vaildDate, setVaildDate] = useState(null);
	const [nameOnCard, setNameOnCard] = useState(null);
	const orderContent = JSON.stringify(cart);
	const res = JSON.parse(localStorage.getItem('res'));

	const [radio1, setRadio1] = useState(false);
	const [radio2, setRadio2] = useState(false);
	const [radio3, setRadio3] = useState(false);
	const [radio4, setRadio4] = useState(false);
	const [radio5, setRadio5] = useState(false);
	const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

	function simulateMouseClick(element) {
		mouseClickEvents.forEach((mouseEventType) =>
			element.dispatchEvent(
				new MouseEvent(mouseEventType, {
					view: window,
					bubbles: true,
					cancelable: true,
					buttons: 1,
				})
			)
		);
	}
	//back ot top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	// document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' });

	const addItem = (item) => {
		dispatch(addCart(item));
	};

	const delItem = (item) => {
		dispatch(delCart(item));
	};
	// const [validated, setValidated] = useState(false);
	const [access_token, setAccess_token] = useState('');
	const [userid, setUserid] = useState('50');
	// const [access_token, setAccess_token] = useState("");
	const handleWechatPay = async (e) => {
		setRadio3(true);
		e.preventDefault();
		e.stopPropagation();
		var orderContentTemplate = '';
		for (var i = 0; i < cart.length; i++) {
			//console.log(cart.length)
			orderContentTemplate += cart[i]['name'];
			orderContentTemplate += ':';
			orderContentTemplate += cart[i]['qty'];
			orderContentTemplate += ';';
		}
		if (res.id && userid && totalPrice && orderContent && orderContentTemplate && num) {
			console.log('userid:' + userid);
			const data = await WechatPay(
				res.id,
				// userid,
				totalPrice.toString(),
				orderContent, //nescc2
				orderContentTemplate,
				num.toString(),
				dineIn == 'true' ? tableNumber.toString() : `Pick Up(Table: ${tableNumber})` + pickUpTime
			);
			if (data && data.ret == 200 && data.data.return_code != 'FAILD') {
				window.location.href = data.data.pay_url;
			} else {
				console.log('data', data);
				history.push('/failure');
			}
		} else {
			alert('No price or content,please reselect the dishes.');
		}
	};
	const handleToPay = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (nameOnCard && cardNumber && vaildDate && cvv) {
			const data = await NewOrder(
				access_token,
				userid,
				res.id.toString(), //nescc
				res.types,
				orderContent, //nescc
				num, //nescc
				totalPrice.toString(), //nescc
				cardNumber, //nescc
				dineIn == true ? tableNumber : `Pick Up(Table: ${tableNumber})` + pickUpTime,
				nameOnCard, //nescc
				tel,
				address,
				postcode,
				vaildDate.split('/')[0], //nescc
				vaildDate.split('/')[1], //nescc
				cvv,
				beizhu
			);
			if (data && data.ret == 200 && data.data.paymentResult) {
				localStorage.setItem('payerId', JSON.stringify(data.data.id));
				history.push(`/thanks`);
			} else {
				history.push('/failure');
			}
		} else if (!nameOnCard) {
			alert(English ? 'Please enter your name!' : '请输入您的姓名！');
		} else if (!cardNumber) {
			alert(English ? 'Please enter your card number!' : '请输入您的卡号！');
		} else if (!vaildDate) {
			alert(English ? 'Please enter the expire date!' : '请输入有效日期！');
		} else if (!cvv) {
			alert(English ? 'Please enter cvv!' : '请输入cvv！');
		}
		// console.log("=> data", data);
		// }
	};

	const payWithDebounce = debounce(handleToPay, 3000);

	const [paypaldetail, setPaypalDetail] = useState(null);
	const time = localStorage.getItem('pickUpTime');
	const handleGooglePayment = async () => {
		const data = await NewPaypalOrder(
			access_token,
			userid,
			res.id.toString(), //nescc
			res.types,
			orderContent, //nescc
			num, //nescc
			totalPrice.toString(), //nescc
			'', //到店人数
			dineIn == 'true' ? tableNumber.toString() : `Pick Up(Table: ${tableNumber})` + pickUpTime,
			JSON.parse(localStorage.getItem('paypaldetail')).payer.name.given_name, //nescc???
			tel,
			address,
			postcode,
			JSON.parse(localStorage.getItem('paypaldetail')).id, //nescc
			beizhu,
			'AfZLZe61Uzn0YppJIoV51OL7O3rkH4OaKZM-OnAM_-hEYhKY57rfQBpf8JQG6b8OnzBrxJFswX8OMDoT'
		);
		console.log('=> data', data);
		if (data && data.ret === 200 && data.data.paymentResult) {
			localStorage.setItem('orderid', data.data.id);
			// console.log("=> data", data.data);
			//history.push(`/thanks`);
		} else {
			history.push('/failure');
		}
		// console.log('handleGooglePayment');
	};

	const handleCardPayment = async () => {
		setRadio1(true);
		history.push('/cardpay');
	};

	const formatString = (e) => {
		var inputChar = String.fromCharCode(e.keyCode);
		var code = e.keyCode;
		var allowedKeys = [8];
		if (allowedKeys.indexOf(code) !== -1) {
			return;
		}

		e.target.value = e.target.value
			.replace(
				/^([1-9]\/|[2-9])$/g,
				'0$1/' // 3 > 03/
			)
			.replace(
				/^(0[1-9]|1[0-2])$/g,
				'$1/' // 11 > 11/
			)
			.replace(
				/^([0-1])([3-9])$/g,
				'0$1/$2' // 13 > 01/3
			)
			.replace(
				/^(0?[1-9]|1[0-2])([0-9]{2})$/g,
				'$1/$2' // 141 > 01/41
			)
			.replace(
				/^([0]+)\/|[0]+$/g,
				'0' // 0/ > 0 and 00 > 0
			)
			.replace(
				/[^\d\/]|^[\/]*$/g,
				'' // To allow only digits and `/`
			)
			.replace(
				/\/\//g,
				'/' // Prevent entering more than 1 `/`
			);
	};

	const handleInputFocus = ({ target }) => {
		this.setState({
			focused: target.name,
		});
	};

	const handleInputChange = ({ target }) => {
		if (target.name === 'number') {
			target.value = formatCreditCardNumber(target.value);
		} else if (target.name === 'expiry') {
			target.value = formatExpirationDate(target.value);
		} else if (target.name === 'cvc') {
			target.value = formatCVC(target.value);
		}

		this.setState({ [target.name]: target.value });
	};

	const handleCardNumber = (e) => {
		setCardNumber(e.target.value);
	};
	//console.log('cart', cart);
	const tips = JSON.parse(localStorage.getItem('tips' || '{}'));
	//console.log('=> tips', tips);

	const totalPrice = Math.abs(cart.reduce((a, b) => a + b.price1 * b.qty, 0) - tips);
	const totalSaved = cart.reduce((a, b) => a + b.price * b.qty, 0) - totalPrice;
	// console.log(
	// 	'=> totalSaved',
	// 	cart.reduce((a, b) => a + b.price * b.qty, 0)
	// );

	localStorage.setItem('cusName', JSON.stringify(nameOnCard));
	const [popOpen, setPopOpen] = useState(false);
	const closePopup = async () => setPopOpen(false);
	const handlePopup = async () => {
		setRadio2(true);
		setPopOpen(!popOpen);
	};

	function isWeiXin() {
		var ua = window.navigator.userAgent.toLowerCase();
		if (ua.match(/MicroMessenger/i) == 'micromessenger') {
			return true; // 是微信端
		} else {
			return false;
		}
	}

	const classes = useStyles();
	const [expanded, setExpanded] = useState('panel1');

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<div>
				<Navbar color='light' expand='lg' className='navbar-light osahan-nav shadow-sm'>
					<Container>
						<Navbar.Brand to='/'>
							<Row>
								<button
									style={{
										color: 'black',
										border: 'none',
										backgroundColor: 'transparent',
									}}
									onClick={() => history.push(`/r/res=${res.id}&type=${res.types}?t=${tableNumber}`)}>
									<RiArrowGoBackLine size='1.5rem' /> Go Back
								</button>
							</Row>
						</Navbar.Brand>
					</Container>
				</Navbar>
			</div>
			<section className='offer-dedicated-body  mb-4 pt-2 pb-2'>
				{/* <AddAddressModal /> */}
				<Container>
					<Row className='d-flex justify-content-center' style={{ padding: 20 }}>
						<Col md={8}>
							<div className='offer-dedicated-body-left'>
								<div className='pt-2'></div>
								<div className='bg-white rounded shadow-sm p-4 osahan-payment'>
									<h4 className='mb-3' style={{ textAlign: 'center' }}>
										{English ? 'Choose payment method' : '请选择付款方式'}
									</h4>
									<div style={{color:'red',textAlign: 'center'}}>*微信支付需通过微信扫码才能使用</div>
									<Tab.Container id='left-tabs-example' defaultActiveKey='first'>
										<Row className='d-flex justify-content-center'>
											<Col sm={6}>
												<Nav variant='pills' className='flex-column'>
													<Nav.Link eventKey='first' onClick={handleCardPayment}>
														<Row>
															<Col md={2} xs={2}>
																<IconContext.Provider
																	value={{
																		color: '#F4811C',
																		className: 'react-icons',
																	}}>
																	<div>
																		<BsCreditCardFill size={30} />
																	</div>
																</IconContext.Provider>
															</Col>
															<Col md={7} xs={7}>
																Credit Cards
															</Col>
															<Col md={1} xs={1}>
																<input
																	type='radio'
																	checked={radio1}
																	style={{ margin: '5px 0 0 0' }}
																/>
															</Col>
														</Row>
													</Nav.Link>
													<Nav.Link eventKey='second' onClick={handlePopup}>
														<Row>
															<Col md={2} xs={2}>
																<IconContext.Provider
																	value={{
																		color: '#190CCD',
																		className: 'react-icons',
																	}}>
																	<div>
																		<FaPaypal size={30} />
																	</div>
																</IconContext.Provider>
															</Col>
															<Col md={7} xs={7}>
																Paypal
															</Col>
															<Col md={1} xs={1}>
																<input
																	type='radio'
																	checked={radio2}
																	style={{ margin: '5px 0 0 0' }}
																/>
															</Col>
														</Row>

														<Row>
															<Modal show={popOpen}>
																<Modal.Header>
																	<Modal.Title>Confirm Paypal payment</Modal.Title>
																</Modal.Header>
																<Modal.Body>
																	<PayPalScriptProvider
																		options={{
																			'client-id':
																				//test
																				// 'ATNZQo_HX28tih5X8PKCug1F4_er2OLCND3TPvkHfAgG6xayRuYWL7_ONQakNpizk1Mj5LGm04vBYRKl',
																				// Outz
																				'AfZLZe61Uzn0YppJIoV51OL7O3rkH4OaKZM-OnAM_-hEYhKY57rfQBpf8JQG6b8OnzBrxJFswX8OMDoT',
																			currency: 'AUD',
																		}}>
																		<PayPalButtons
																			style={{
																				layout: 'horizontal',
																			}}
																			createOrder={(data, actions) => {
																				return actions.order.create({
																					purchase_units: [
																						{
																							amount: {
																								value: (
																									totalPrice / 100
																								).toFixed(2),
																							},
																						},
																					],
																				});
																			}}
																			onApprove={(data, actions) => {
																				return actions.order
																					.capture()
																					.then((details) => {
																						console.log(
																							'=> details',
																							details
																						);
																						console.log('=> data', data);
																						const name =
																							details.payer.name
																								.given_name;
																						const id = details.id;
																						console.log(details);
																						console.log(details.status);
																						console.log(id);
																						localStorage.setItem(
																							'paypaldetail',
																							JSON.stringify(details)
																						);
																						localStorage.setItem(
																							'paypaldata',
																							JSON.stringify(data)
																						);
																						if (
																							details.status ==
																							'COMPLETED'
																						) {
																							console.log(
																								'name:',
																								JSON.parse(
																									localStorage.getItem(
																										'paypaldetail'
																									)
																								).payer.name.given_name
																							);
																							handleGooglePayment(id);
																							history.push(
																								`/thanks?p&${name}`
																							);
																						} else {
																							console.log(
																								'something wrong with status'
																							);
																						}
																					});
																			}}
																			onError={(err) =>
																				err && history.push('/failure')
																			}
																		/>
																	</PayPalScriptProvider>
																</Modal.Body>
																<Modal.Footer>
																	<Button variant='secondary' onClick={closePopup}>
																		Close
																	</Button>
																</Modal.Footer>
															</Modal>
														</Row>
													</Nav.Link>

													<Nav.Link eventKey='third' onClick={handleWechatPay}>
														<Row>
															<Col md={2} xs={2}>
																<IconContext.Provider
																	value={{
																		color: 'green',
																		className: 'react-icons',
																	}}>
																	<div>
																		<AiFillWechat size={30} />
																	</div>
																</IconContext.Provider>
															</Col>
															<Col md={7} xs={7}>
																Wechat
															</Col>
															<Col md={1} xs={1}>
																<input
																	type='radio'
																	checked={radio3}
																	style={{ margin: '5px 0 0 0' }}
																/>
															</Col>
														</Row>
													</Nav.Link>


													{/*
													<Nav.Link eventKey='fourth'>
														<Row>
															<Col md={2} xs={2}>
																<IconContext.Provider value={{ color: "#1BC5F5", className: "react-icons" }}>
																	<div>
																		<AiFillAlipayCircle size={30}/>
																	</div>
																</IconContext.Provider>
															</Col>
															<Col md={7} xs={7}>
																Alipay
															</Col>
															<Col md={1} xs={1}>
																<input
																	type='radio'
																	style={{ margin: '5px 0 0 0' }}
																/>
															</Col>
														</Row>
													</Nav.Link>
													<Nav.Link eventKey='fifth'>
														<Row>
															<Col md={2} xs={2}>
																<Icofont icon='apple-pay' />
															</Col>
															<Col md={7} xs={7}>
																Apple pay
															</Col>
															<Col md={1} xs={1}>
																<input
																	type='radio'
																	style={{ margin: '5px 0 0 0' }}
																/>
															</Col>
														</Row>
													</Nav.Link>
													*/}
												</Nav>
											</Col>
											{/*
											<Col sm={8} className='pl-0'>
												<Tab.Content className='h-100'>
													<Tab.Pane eventKey='first'>
														<h6 className='mb-3 mt-0 mb-3'>
															{English ? 'Add new card' : '添加新卡'}
														</h6>
														<p>
															WE ACCEPT{' '}
															<span className='osahan-card'>
																<Icofont icon='visa-alt' />{' '}
																<Icofont icon='mastercard-alt' />{' '}
																<Icofont icon='american-express-alt' />{' '}
																<Icofont icon='paypal-alt' />{' '}
															</span>
														</p>

														<div className='form-row'>
															<Form.Group className='col-md-12'>
																<Form.Label>Card number</Form.Label>
																<InputGroup>
																	<Form.Control
																		type='text'
																		placeholder='Card number'
																		maxLength='16'
																		onKeyPress={(event) => {
																			if (!/[0-9]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}}
																		// max='16'
																		onChange={(e) => handleCardNumber(e)}
																	/>

																	{/* <InputGroup>
																			<Button
																				variant='outline-secondary'
																				type='button'
																				id='button-addon2'>
																				<Icofont icon='card' />
																			</Button>
																		</InputGroup> *}
																</InputGroup>
																{cardNumber?.length !== 0 && cardNumber?.length < 14 && (
																	<p
																		className='text-danger mt-1'
																		style={{
																			marginLeft: '2px',
																		}}>
																		Valid card number is required!
																	</p>
																)}
															</Form.Group>
															<Form.Group className='col-md-8'>
																<Form.Label>Expiry (MM/YY)</Form.Label>

																<Form.Control
																	type='text'
																	maxLength='5'
																	placeholder='Enter Valid through(MM/YY)'
																	onKeyUp={(e) => formatString(e)}
																	onChange={(e) => setVaildDate(e.target.value)}
																/>
															</Form.Group>
															<Form.Group className='col-md-4'>
																<Form.Label>CVC</Form.Label>
																<Form.Control
																	type='number'
																	placeholder='Enter CVC Number'
																	maxLength='3'
																	onChange={(e) => setCvv(e.target.value)}
																/>
															</Form.Group>
															<Form.Group className='col-md-12 mb-2'>
																<Form.Label>Name on card</Form.Label>
																<Form.Control
																	type='text'
																	placeholder='Enter name on the card'
																	onChange={(e) => setNameOnCard(e.target.value)}
																/>
															</Form.Group>

															<button
																// to=""
																// to='/thanks'
																type='submit'
																className='btn btn-success btn-block btn-lg'
																onClick={payWithDebounce}>
																PAY ${(totalPrice / 100).toFixed(2)}
																<Icofont icon='long-arrow-right' />
															</button>
														</div>
													</Tab.Pane>
												</Tab.Content>
											</Col>
											*/}
										</Row>
									</Tab.Container>
								</div>
							</div>
						</Col>
					</Row>
					

					<div className={classes.root}>
						<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1bh-content'
								id='panel1bh-header'>
								<Typography className={classes.heading}>Your order:</Typography>
							</AccordionSummary>
							<AccordionDetails className='p-2'>
								<Row className='d-flex justify-content-center w-100 m-0 p-0'>
									<Col className='p-0'>
										<div className='generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item w-100'>
											<div className='d-flex mb-4 osahan-cart-item-profile'>
												<div className='d-flex flex-column'>
													<h6 className='mb-1 text-white'>{res.name}</h6>
												</div>
											</div>
											<div className='bg-white rounded shadow-sm mb-2'>
												{cart &&
													cart
														.filter((i) => i.enname !== 'Tips')
														.map((item, index) => (
															<div
																key={item.id}
																className='gold-members p-2 border-bottom'>
																<p className='text-gray mb-0 float-right ml-2'></p>
																<span className='count-number float-right'>
																	{cart.find((x) => x.id == item.id)
																		? 'x ' + cart.find((x) => x.id == item.id).qty
																		: 0}
																</span>
																<div className='media'>
																	<div className='mr-2'>
																		<Icofont
																			icon='ui-press'
																			className='text-danger food-item'
																		/>
																	</div>
																	<div className='media-body'>
																		<p className='mt-1 mb-0 text-black'>
																			{English ? item.enname : item.name}
																		</p>
																	</div>
																</div>
															</div>
														))}
											</div>

											<div className='mb-2 bg-white rounded p-2 clearfix'>
												<p className='mb-1'>
													Item Total{' '}
													<span className='float-right text-dark'>
														${(totalPrice / 100).toFixed(2)}
													</span>
												</p>
												<strong className='mb-1'>
													Tips{' '}
													<span className='float-right text-dark'>
														${(tips / 100).toFixed(2)}
													</span>
												</strong>

												{/* <p className='mb-1 text-success'>
													Saved:
													<span className='float-right text-success'>
														$ {(totalSaved / 100).toFixed(2)}
													</span>
												</p>
												<p className='mb-1'>Login/Register to unlock your discount!</p> */}
												<hr />
												<h6 className='font-weight-bold mb-0'>
													TO PAY(GST included)
													<span className='float-right'>
														${((totalPrice + tips) / 100).toFixed(2)}
													</span>
												</h6>
											</div>
										</div>
										<div className='pt-2'></div>
									</Col>
								</Row>
							</AccordionDetails>
						</Accordion>
						{/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel2bh-content'
								id='panel2bh-header'>
								<Typography className={classes.heading}>Login/Register</Typography>
							</AccordionSummary>
							<AccordionDetails className='d-flex justify-content-center'>
								<h4 className='text-center'>Already an Outz member?</h4>
							</AccordionDetails>
						</Accordion> */}
					</div>
				</Container>
			</section>
		</>
	);
};

export default Checkout;
