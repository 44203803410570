// import 'react-timeit/dist/index.css';

import { Button, Col, Container, Image, Modal, Offcanvas, Row } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';

import DatePicker from 'react-mobile-datepicker';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { ResidgetOpenhour } from '../../api/API';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

const useStyles = makeStyles({
	root: {
		position: 'relative',
		border: 'none',
		opacity: '0',
		height: '40px',
		// zIndex: '-10',
		width: '100%',
	},
	madol: {
		opacity: '0',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: '80px !important',
		// borderRadius: '10px',
		// opacity: '0',
	},
});

const DineInOrPickUp = ({
	English,
	takeaway,
	selectedDineIn,
	selectedPickUp,
	handleTimeChange,
	changeCurrentState,
}) => {
	const [time, setTime] = useState(new Date());
	const [isOpen, SetIsOpen] = useState(false);

	const [maxTime, setMaxTime] = useState(moment().endOf('day').subtract(2.5, 'hours').toDate());

	// let endOfTheDay = moment().endOf('day').subtract(2.5, 'hours');
	// console.log('=> endOfTheDay', endOfTheDay);
	// console.log('=> maxTime', maxTime);

	const handleClick = () => {
		SetIsOpen(true);
		selectedPickUp();
		if (takeaway === false) {
			return;
		} else {
			changeCurrentState();
		}
	};

	const handleCancel = () => {
		console.log('cancel', time);
		SetIsOpen(false);
		handleTimeChange('ASAP');
		if (takeaway === false) {
			return;
		} else {
			changeCurrentState();
		}
	};

	const handleSelect = (time) => {
		setTime(time);
		handleTimeChange(time);
		SetIsOpen(false);
		// console.log('=> handleSelect', handleSelect);
		// if (takeaway === false) {
		// 	return changeCurrentState();
		// } else {
		// 	// changeCurrentState();
		// }
	};
	const dateConfig = {
		hour: {
			format: 'hh',
			caption: 'Hour',
			step: 1,
		},
		minute: {
			format: 'mm',
			caption: 'Min',
			step: 1,
		},
	};

	const classes = useStyles();

	return (
		<>
			<Dialog
				open={takeaway}
				className={classes.modal}
				PaperProps={{
					sx: {
						backgroundColor: 'transparent',
						width: '100%',
						height: '100%',
						margin: '0',
						maxHeight: '100%',
					},
				}}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogContent className={classes.content}>
					<Button className='w-100 border-0 takeaway-button' onClick={() => selectedDineIn()}>
						Dine in
					</Button>
					{maxTime - time > 0 && (
						<>
							<hr className='m-0' />
							<button
								className='d-flex justify-content-center w-100 border-0 takeaway-button'
								onClick={() => handleClick()}>
								<div className='upper-layer'>Pick up</div>
								<MdOutlineKeyboardArrowDown className='down-arrow' />
								{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
							<MobileTimePicker
								value={selectedTime}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField label={'pickup'} className={classes.root} {...params} />
								)}
								DialogProps={(props) => console.log(props)}
							/>
						</LocalizationProvider> */}
							</button>
						</>
					)}
				</DialogContent>
			</Dialog>

			{maxTime - time > 0 && (
				<DatePicker
					theme='ios'
					showCaption={true}
					dateConfig={dateConfig}
					min={time}
					max={maxTime}
					value={time}
					isOpen={isOpen}
					onSelect={handleSelect}
					onCancel={handleCancel}
					// isPopup={true}
					cancelText={English ? 'ASAP' : '尽快'}
					confirmText={English ? 'OK' : '完成'}
				/>
			)}
		</>
	);
};

export default DineInOrPickUp;
