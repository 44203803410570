const takeaway = true;

const handleTakeaway = (state = takeaway, action) => {
	switch (action.type) {
		case 'CHANGE_TAKEAWAY':
			return !state;

		default:
			return state;
	}
};

export default handleTakeaway;
