//For Add Item to Cart
export const addCart = (product) => {
	return {
		type: 'ADDITEM',
		payload: product,
	};
};

//For Delete Item to Cart
export const delCart = (product) => {
	return {
		type: 'DELITEM',
		payload: product,
	};
};

//update Item in Cart
export const updateCart = (product) => {
	return {
		type: 'UPDATEITEM',
		payload: product,
	};
};

//reset the cart
export const resetCart = () => {
	return {
		type: 'RESET',
	};
};

export const setFoodListHeight = (value) => {
	return {
		type: 'SET_FOOD_LIST_HEIGHT',
		value,
	};
};

export const setNavPos = (value) => {
	return {
		type: 'SET_NAV_POS',
		value,
	};
};

export const setListGroupHeight = (value) => {
	return {
		type: 'SET_LIST_GROUP_HEIGHT',
		value,
	};
};

export const changeCurrentGroup = (index) => {
	return {
		type: 'CHANGE_CURRENT_GROUP',
		index,
	};
};

export const changeTakeaway = (index) => {
	return {
		type: 'CHANGE_TAKEAWAY',
		payload: index,
	};
};

export const changePickUpTime = (index) => {
	return {
		type: 'PICK_UP_TIME',
		payload: index,
	};
};

/**
 * 保存列表状态
 * @param data
 * @returns {Function}
 */
export const saveListState = (data) => {
	return {
		type: 'LIST_STATE',
		payload: data,
	};
};

/**
 * 清除列表状态
 * @returns {Function}
 */
export const clearListState = () => {
	return {
		type: 'CLEAR_LIST_STATE',
	};
};
