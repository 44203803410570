import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-select2-wrapper/css/select2.css';
import './App.css';

import { AliveScope, KeepAlive, useActivate } from 'react-activation';
import { Route, Switch, withRouter } from 'react-router-dom';

import Addcard from './components/Addcard';
import Cardpay from './components/Cardpay';
import Checkout from './components/Checkout';
import CourseDetail from './components/CourseDetail';
import Detail from './components/Detail';
import Extra from './components/Extra';
import Failure from './components/Failure';
import Footer from './components/common/Footer';
import Header from './components/common/Header';
import Index from './components/Index';
import Invoice from './components/Invoice';
import List from './components/List';
import Login from './components/Login';
import MyAccount from './components/MyAccount';
import NotFound from './components/NotFound';
import NotLiveRoute from 'react-live-route';
import Offers from './components/Offers';
import React from 'react';
import Register from './components/Register';
import Thanks from './components/Thanks';
import TrackOrder from './components/TrackOrder';

// import { withRouter } from 'react-router-dom';

// const LiveRoute = withRouter(NotLiveRoute);
class App extends React.Component {
	render() {
		return (
			<>
				{/* {this.props.location.pathname !== '/login' &&
				this.props.location.pathname !== '/register' &&
				this.props.location.pathname !== '/checkout' &&
				this.props.location.pathname !== '/cardpay' &&
				this.props.location.pathname !== '/addcard' &&
				this.props.location.pathname !== '/coursedetail' ? (
					<Header />
				) : (
					''
				)} */}

				<AliveScope>
					<Switch>
						<Route path='/offers' exact component={Offers} />
						<Route path='/listing' exact component={List} />
						<Route path='/myaccount' component={MyAccount} />
						<Route path='/404' exact component={NotFound} />
						<Route path='/extra' exact component={Extra} />
						<Route path='/login' exact component={Login} />
						<Route path='/register' exact component={Register} />
						<Route path='/track-order' exact component={TrackOrder} />
						<Route path='/invoice' exact component={Invoice} />
						<Route path='/checkout' exact component={Checkout} />
						<Route path='/thanks' exact component={Thanks} />
						<Route path='/failure' exact component={Failure} />
						<Route path='/cardpay' exact component={Cardpay} />
						<Route path='/coursedetail' exact component={CourseDetail} />
						<Route path='/addcard' exact component={Addcard} />
						<KeepAlive name='List'>
							<Route exact path='/r/:ids' component={Detail} />
						</KeepAlive>
						{/* <LiveRoute path='/r/:ids' livePath='/coursedetail' component={Detail} /> */}
						<Route exact component={NotFound} />
						{/* <Route path='/' component={Index} /> */}
					</Switch>
				</AliveScope>
				{this.props.location.pathname === '/thanks' && this.props.location.pathname === '/checkout' ? (
					<Footer />
				) : (
					''
				)}
			</>
		);
	}
}

export default App;
