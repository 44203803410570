import { Badge, CartIcon, FooterWrapper, SettleButton, TableWrapper, ViewCart } from './style';
import { IdgetRestaurant, ResidgetCourseortype, ResidgetOpenhour } from '../api/API';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { addCart, changePickUpTime, changeTakeaway, delCart } from './redux/action';
import { useDispatch, useSelector } from 'react-redux';

import { AiOutlineShoppingCart } from 'react-icons/ai';
import { AliveScope } from 'react-activation';
import Bar from './sections/Bar';
import { Container } from 'react-bootstrap';
import DineInOrPickUp from './sections/DineInOrPickUp';
import FoodSection from './sections/FoodSection';
import Icofont from 'react-icofont';
import KeepAlive from 'react-activation';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import Slider from './sections/Slider';
import TimePicker from 'react-bootstrap-time-picker';
import TipsModel from './sections/TipsModel';
import YouMayLike from './sections/YouMayLike';
import moment from 'moment';

// import CourseDetail from './sections/CourseDetail';

const Detail = () => {
	const [English, setEnglish] = useState(true);
	// const [takeaway, setTakeaway] = useState(localStorage.getItem('dineIn') ? true : false);
	const [dineIn, setDineIn] = useState(false);
	const [pickUp, setPickUp] = useState(false);
	const location = useLocation();
	// console.log('=> location', location);
	const history = useHistory();
	var ual = navigator.language;
	var baseLang = 'zh';
	baseLang = ual ? ual.substring(0, 2).toLowerCase() : 'zh';
	localStorage.setItem('baseLang', JSON.stringify(baseLang));
	useEffect(() => {
		if (baseLang == 'zh') {
			setEnglish(false);
		}
	}, []);
	const foodListRef = useRef();
	const footBar = useRef();
	const path = location.pathname.split('res')[1];
	const tableNumber = location.search.split('=')[1];
	localStorage.setItem('tableNumber', JSON.stringify(tableNumber));
	const [order, setOrder] = useState();
	const payerID = JSON.parse(localStorage.getItem('payerId'));
	// console.log('=> payerID', payerID);

	//rule => /res=(id)&&type=()?t=(table number)
	const [resId, setResId] = useState(path?.split('&')[0]?.split('=')[1]);
	const [serviceType, setServiceType] = useState(path?.split('&')[1]?.split('=')[1]);
	const [courseType, setCourseType] = useState([]);
	const [course, setCourse] = useState([]);
	const token =
		'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJiOGUxYjIwOSIsImF1ZCI6InVzZXIiLCJzdWIiOiJHUHYzR3duVnB1c3giLCJ1aWQiOjQzLCJpYXQiOjE2MzY3MTc2MzgsImV4cCI6MTYzOTMwOTYzOH0.rdmSYhl7zpf0NDUhJsadkEBuXEW9Hpxgw6NjAWNuSYc';
	const [res, setRes] = useState();
	localStorage.setItem('resInfo', JSON.stringify({ resId, serviceType }));

	const dispatch = useDispatch();
	const cart = useSelector((state) => state.cart);
	const takeaway = useSelector((state) => state.takeaway);
	const pickUpTime = useSelector((state) => state.time);
	//change pick up or takeaway
	const changeCurrentState = (takeaway) => {
		dispatch(changeTakeaway(takeaway));
	};
	const changeTime = (time) => {
		dispatch(changePickUpTime(time));
	};

	// Action to ADD/DEL item to/from cart list
	const addItem = (item, e) => {
		dispatch(addCart(item));
	};
	const delItem = (item, e) => {
		dispatch(delCart(item));
	};

	const getRes = async () => {
		const data = await IdgetRestaurant(token, Number(resId));
		// console.log(data);
		setRes(data.data);
	};

	const [times, setTimes] = useState('');
	// console.log('=> times', times);
	// console.log('=> dinein', dineIn);

	const openTime = async () => {
		const data = await ResidgetOpenhour(token, Number(resId), 0);
		setTimes(data.data);
	};
	localStorage.setItem('res', JSON.stringify(res));

	useEffect(() => {
		getCourses();
		getRes();
		openTime();
	}, []);

	useEffect(() => {
		if (res) document.title = `Outz - ${res?.name}`;
		window.addEventListener('scroll', handleScroll, true);
	}, [res]);

	const getCourses = async () => {
		const idGetCourses = await ResidgetCourseortype(token, resId, serviceType);

		setCourseType(idGetCourses?.data.coursetype);
		setCourse(idGetCourses?.data.course);
	};

	//calculate the total price
	const totalPrice = cart.reduce((a, b) => {
		return a + b.price1 * b.qty;
	}, 0);
	const totalSaved = cart.reduce((a, b) => a + b.price1 * b.qty, 0) - totalPrice;

	//set active Menu
	const [activeIndex, setActiveIndex] = useState();
	const handleSelect = (index) => {
		handleNavClick(index);
		setActiveIndex(index);
	};

	//lfet side
	const [foodListHeight, setFoodListHeight] = useState();
	const [listGroupHeight, setListGroupHeight] = useState();
	const [navPosition, setNavPos] = useState('move');
	const [tablePosition, setTablePos] = useState('');

	useEffect(() => {
		if (courseType.length > 0) {
			const height = foodListRef.current?.clientHeight;
			// console.log('=> foodListRef.current', foodListRef.current);
			setFoodListHeight(height);
			const listGroupHeight = calculateHeight(foodListRef.current?.children);
			// console.log('=> listGroupHeight', listGroupHeight);
			setListGroupHeight(listGroupHeight);
		}
	}, [course]);

	const calculateHeight = (nodes) => {
		const listGroupHeight = new Array(nodes?.length);
		listGroupHeight[0] = 409;

		for (let i = 1; i < nodes?.length; i++) {
			listGroupHeight[i] = listGroupHeight[i - 1] + nodes[i - 1]?.clientHeight;
		}
		return listGroupHeight;
	};

	const handleNavClick = (i) => {
		const arr = listGroupHeight;

		// this.props.changeCurrentGroup(i)
		window.scrollTo({
			top: arr[i],
			behavior: 'smooth',
		});
	};

	const handleScroll = (e) => {
		// const arr = this.props.listGroupHeight
		const scrollTop = document.documentElement.scrollTop;
		// console.log('scrollTop', scrollTop);
		if (scrollTop > 409) {
			setNavPos('fixed');
			// e.preventShake(e.watchCurrentGroup, 16)();
		}
		// if (navPosition === 'fixed') {
		if (scrollTop <= 409) {
			setNavPos('move');
			// }
		}
	};

	//filter courses are not in cart
	const [newArr, setNewArr] = useState([]);

	// newArr;
	localStorage.setItem('newArr', JSON.stringify(newArr));

	//Bar area
	//如果bar一直处于隐藏状态，就不重新渲染它
	const [barIsVisible, setBarIsVisible] = useState(false);
	const [barHeight, setBarHeight] = useState(54);
	const [changeBarReady, setChangeBarReady] = useState(false);
	const [changeBarShow, setChangeBarShow] = useState(false);
	const [cartList, setCartList] = useState(false);
	//changeBarShow
	//barHeight: 54,

	//footer area
	const showBadge = (e) => {
		// console.log('eeeeee', e);
		if (cart.length === 0) {
			return null;
		} else {
			return <Badge>{cart.reduce((a, b) => a + b.qty, 0)}</Badge>;
		}
	};

	useEffect(() => {
		if (changeBarReady) {
			// 当且仅当readyToHideBar从false变为true时，重新计算高度
			const height = footBar.current.clientHeight;
			setBarHeight(height);
			setChangeBarShow(false);
			setChangeBarReady(false);
		}
	}, []);

	const toggleBar = () => {
		if (barIsVisible) {
			//准备隐藏bar
			setChangeBarReady(true);
			setBarIsVisible(false);
			setCartList(false);
		} else {
			//准备显示bar
			setChangeBarShow(true);
			setBarIsVisible(true);
			setCartList(true);
		}
	};

	//This area control Tips modal
	const [showOffcanvas, setShowOffcanvas] = useState(false);
	// console.log('=> showOffcanvas', showOffcanvas);

	const handleCloseOffcanvas = () => {
		setShowOffcanvas(false);
		setSelectedIndex(-1);
		setTips(0);
	};
	const handleShowOffcanvas = () => setShowOffcanvas(true);

	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [tips, setTips] = useState(0);
	const [nextTime, setNextTime] = useState(true);
	localStorage.setItem('tips', JSON.stringify(tips));
	const handleSelectedTips = (item, index, price) => {
		// setBtnClicked(!btnClicked);
		setSelectedIndex(index);
		setTips(price);
		// console.log(item);
	};

	const handleNextTime = () => {
		setShowOffcanvas(false);
		// toggleBar();
		setSelectedIndex(-1);
		setTips(0);
		history.push('../checkout');
	};
	const handleAddTips = () => {
		if (tips != 0) {
			addItem({
				id: '0000',
				name: '小费',
				enname: 'Tips',
				price: 0,
				price1: tips,
			});
		}
		setShowOffcanvas(false);
		// toggleBar();

		history.push('../checkout');

		// console.log('tips', cart);
	};

	//choose dine in or pick up
	const selectedDineIn = () => {
		setDineIn(true);
		localStorage.setItem('dineIn', true);
		changeCurrentState();
		// console.log('Dine in:', localStorage.getItem('dineIn'));

		// setTakeaway(false);
	};

	const selectedPickUp = () => {
		setDineIn(false);
		localStorage.setItem('dineIn', false);
		console.log('Dine in:', localStorage.getItem('dineIn'));
		// setTakeaway(false);
	};

	const handleScrollTop = () => {
		console.log('Scroll', 'top');
		window.scrollTo(0, 0);
	};

	const handleChangeTakeaway = (value) => {
		changeCurrentState();
		// setTakeaway(true);
		handleScrollTop();
	};
	// const [pickUpTime, setPickUpTime] = useState();

	const handleTimeChange = (time) => {
		if (time == 'ASAP') {
			changeTime('ASAP');
		} else {
			const actualTime = moment(time).format('HH:mm ');
			changeTime(actualTime);
		}
		setDineIn(false);
		document.body.style.overflow = 'auto';
	};

	//handle checkout
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	const handleShow = () => setShow(true);

	const handleCheckout = () => {
		// console.log('cart', cart);
		localStorage.setItem('firstCart', JSON.stringify(cart));
		if (!dineIn) {
			if (cart.length > 0 && !show) {
				let arr1Ids = cart.map((item) => item.id);
				const a = course
					.filter((item) => !arr1Ids.includes(item.id))
					.sort((a, b) => a?.price1 - b?.price1)
					.slice(0, 3);
				setNewArr(a);
				handleShow();
			} else if (cart.length > 0 && show) {
				toggleBar();
				handleClose();
				handleShowOffcanvas();
				// history.push('../checkout');
			}
		} else {
			if (cart.length > 0 && !show) {
				let arr1Ids = cart.map((item) => item.id);
				const a = course
					.filter((item) => !arr1Ids.includes(item.id))
					.sort((a, b) => a?.price1 - b?.price1)
					.slice(0, 3);
				setNewArr(a);
				handleShow();
			} else if (cart.length > 0 && show) {
				console.log('FFFFF');
				toggleBar();
				handleClose();
				handleShowOffcanvas();
				// history.push('../checkout');
			}
		}
	};

	return (
		<>
			{res && (
				<div style={{ overflowY: 'scroll' }}>
					<section className='restaurant-detailed-banner'>
						<div className='text-center'>
							<Slider res={res} />
						</div>
					</section>
					<div className='offer-dedicated-nav bg-white border-top-0 '>
						<Container style={{ backgroundColor: '#fff' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									width: '100%',
								}}>
								<div
									className='d-flex justify-content-center align-items-center'
									style={{ height: ' 40px' }}>
									<h2 className='mb-0'>{English ? res.nameen : res.name}</h2>
								</div>
								<p className=' mb-0'>
									<Icofont icon='food-cart' />
									{English ? res.styleen : res.style}
								</p>
							</div>
						</Container>
					</div>

					<TableWrapper>
						<div className={`d-flex w-100 align-items-center justify-content-between table mb-0`}>
							<div className='text-black'> Table {tableNumber}</div>
							{!dineIn ? (
								<>
									<div className='d-flex '>
										<div className='mr-2 d-flex flex-row' onClick={handleChangeTakeaway}>
											Pick Up{' '}
											{pickUpTime ? (
												<div className='ml-2'>
													{pickUpTime} <MdOutlineKeyboardArrowDown />
												</div>
											) : (
												''
											)}
										</div>
									</div>
									<div style={{ visibility: 'hidden' }}>hhhhh</div>
								</>
							) : (
								<>
									<div className='mr-2 d-flex flex-row' onClick={handleChangeTakeaway}>
										Dine in
										<MdOutlineKeyboardArrowDown className='detail-down-arrow' />
									</div>
									<div style={{ visibility: 'hidden' }}>hhhhh</div>
								</>
							)}
						</div>
					</TableWrapper>
					<FoodSection
						navPosition={navPosition}
						foodListHeight={foodListHeight}
						courseType={courseType}
						activeIndex={activeIndex}
						English={English}
						handleSelect={handleSelect}
						foodListRef={foodListRef}
						course={course}
						delItem={delItem}
						cart={cart}
						addItem={addItem}
						history={history}
						// insertTypeToCourse={insertTypeToCourse}
						showBadge={showBadge}
					/>
				</div>
			)}
			{cart.length > 0 && (
				<>
					<Bar
						barIsVisible={barIsVisible}
						cartList={cartList}
						English={English}
						cart={cart}
						delItem={delItem}
						addItem={addItem}
						footBar={footBar}
						barHeight={barHeight}
						toggleBar={toggleBar}
						totalPrice={totalPrice}
						handleCheckout={handleCheckout}
					/>

					<TipsModel
						showOffcanvas={showOffcanvas}
						English={English}
						handleAddTips={handleAddTips}
						handleSelectedTips={handleSelectedTips}
						totalPrice={totalPrice}
						selectedIndex={selectedIndex}
						handleCloseOffcanvas={handleCloseOffcanvas}
						handleNextTime={handleNextTime}
					/>
					<FooterWrapper>
						{/* <CartIcon onClick={toggleBar}>
					<AiOutlineShoppingCart
						theme='filled'
						size='30'
						fill={cart.length > 0 ? '#33CCFF' : '#777'}
						strokeLinecap='butt'
					/>
					
				</CartIcon> */}
						{!barIsVisible && (
							<ViewCart>
								<button className='view' onClick={toggleBar}>
									View cart ({cart.reduce((a, b) => a + b.qty, 0)})
								</button>
							</ViewCart>
						)}
						{/* <SettleButton fill={cart.length} onClick={handleCheckout}>
					{English ? 'Checkout' : '去结算'}
				</SettleButton> */}

						<YouMayLike
							show={show}
							newArr={newArr}
							delItem={delItem}
							cart={cart}
							addItem={addItem}
							handleClose={handleClose}
							handleCheckout={handleCheckout}
							English={English}
							history={history}
						/>
					</FooterWrapper>
				</>
			)}

			<DineInOrPickUp
				takeaway={takeaway}
				selectedDineIn={selectedDineIn}
				selectedPickUp={selectedPickUp}
				//  setDineIn={setDineIn}
				//  dineIn={dineIn}
				//  pickUp={pickUp}
				//  setPickUp={setPickUp}
				handleTimeChange={handleTimeChange}
				pickUpTime={pickUpTime}
				// todo
				changeCurrentState={changeCurrentState}
				// setTakeaway={setTakeaway}
				English={English}
			/>
		</>
	);
};

export default Detail;
