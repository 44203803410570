const initListState = {
	scrollTop: 0, //列表滑动位置
	listData: [], //列表数据
	pageIndex: 1, //当前分页页码
	itemIndex: -1, //点击的条目index
};

const redListState = (state = initListState, action) => {
	if (action === undefined) {
		return state;
	}

	switch (action.type) {
		case 'LIST_STATE':
			//更新列表状态
			return {
				...state,
				...action,
			};
		case 'CLEAR_LIST_STATE':
			//清空列表状态
			return initListState;
		default:
			return state;
	}
};

export default redListState;
