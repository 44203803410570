import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Button, Col, Container, Image, Modal, Offcanvas, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import React from 'react';

const Slider = ({ res }) => {
	return (
		<div>
			<Swiper
				// install Swiper modules
				modules={[Navigation, Pagination]}
				spaceBetween={50}
				slidesPerView={1}
				// navigation
				pagination={{ clickable: true }}
				// scrollbar={{ draggable: true }}
				// onSwiper={(swiper) => console.log(swiper)}
				// onSlideChange={() => console.log('slide change')}
			>
				{res.pic && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic} />
						</div>
					</SwiperSlide>
				)}
				{res.pic2 && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic2} />
						</div>
					</SwiperSlide>
				)}
				{res.pic3 && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic3} />
						</div>
					</SwiperSlide>
				)}
				{res.pic4 && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic4} />
						</div>
					</SwiperSlide>
				)}
				{res.pic5 && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic5} />
						</div>
					</SwiperSlide>
				)}
				{res.pic6 && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic6} />
						</div>
					</SwiperSlide>
				)}
				{res.pic7 && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic7} />
						</div>
					</SwiperSlide>
				)}
				{res.pic8 && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic8} />
						</div>
					</SwiperSlide>
				)}
				{res.pic9 && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic9} />
						</div>
					</SwiperSlide>
				)}
				{res.pic10 && (
					<SwiperSlide>
						<div className='cover'>
							<Image fluid className='cover' style={{ height: '300px' }} src={res.pic10} />
						</div>
					</SwiperSlide>
				)}
			</Swiper>
		</div>
	);
};

export default Slider;
