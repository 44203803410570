import React, { ReactElement } from 'react';

import styled from 'styled-components';

const Icon = styled.svg.attrs({
	version: '1.1',
	xmlns: 'https://www.w3.org/2000/svg',
	xmlnsXlink: 'https://www.w3.org/1999/xlink',
})``;

const Svg = styled(Icon)`
	width: 25px;
	height: 25px;
`;

export const CloseIcon = () => (
	<Svg viewBox='0 0 100 100' fill='#fff'>
		<path
			d='M89.7,10.3L89.7,10.3c-1-1-2.6-1-3.5,0L50,46.5L13.9,10.3c-1-1-2.6-1-3.5,0l0,0c-1,1-1,2.6,0,3.5L46.5,50L10.3,86.1
      c-1,1-1,2.6,0,3.5h0c1,1,2.6,1,3.5,0L50,53.5l36.1,36.1c1,1,2.6,1,3.5,0l0,0c1-1,1-2.6,0-3.5L53.5,50l36.1-36.1
      C90.6,12.9,90.6,11.3,89.7,10.3z'
		/>
	</Svg>
);
