const cart = [];
const handleCart = (state = cart, action) => {
	const product = action.payload;
	switch (action.type) {
		case 'ADDITEM':
			//check if Product is already exist?
			const exist = state.find((x) => x.id === product.id && x?.selectedRules == product.selectedRules);
			if (exist) {
				//Increase the quantity
				return state.map((x) =>
					x.id === product.id && x?.selectedRules == product.selectedRules ? { ...x, qty: x.qty + 1 } : x
				);
			} else {
				console.log('=> product', product);
				if (product.qty && product.qty > 0) {
					return [...state, { ...product }];
				} else {
					return [
						...state,
						{
							...product,
							qty: 1,
						},
					];
				}
			}

		case 'DELITEM':
			const exsit1 = state.find((x) => x.id === product.id);
			console.log(exsit1);
			if (exsit1) {
				if (exsit1.qty === 1) {
					return state.filter((x) => x.id !== exsit1.id);
				} else {
					return state.map((x) => (x.id === product.id ? { ...x, qty: x.qty - 1 } : x));
				}
			} else {
				return [...state];
			}
		case 'UPDATEITEM':
			const exist_2 = state.find((x) => x.id === product.id && x?.selectedRules == product.selectedRules);
			//update the item & quantity
			if (exist_2) {
				return state.map((x) =>
					x.id === product.id && x?.selectedRules == product.selectedRules ? { ...x, qty: product.qty } : x
				);
			} else {
				return [...state, { ...product }];
			}

		case 'RESET':
			return cart;
		default:
			return state;
	}
};

export default handleCart;
