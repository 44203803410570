import 'react-credit-cards/es/styles-compiled.css';

import { Button, Container, Form, InputGroup, Navbar, Row, Spinner } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import Icofont from 'react-icofont';
import { NewOrder } from '../api/API';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { debounce } from './Debounce';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { validateDateTime } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation';

const Cardpay = () => {
	const res = JSON.parse(localStorage.getItem('res'));
	const [tableNumber, setTableNumber] = useState(
		localStorage.getItem('tableNumber') ? JSON.parse(localStorage.getItem('tableNumber')) : 0
	);
	const pickUpTime = useSelector((state) => state.time);
	const history = useHistory();
	const [English, setEnglish] = useState(true);
	var ual = navigator.language;
	var baseLang = 'zh';
	baseLang = ual ? ual.substring(0, 2).toLowerCase() : 'zh';
	const cart = useSelector((state) => state.cart);
	// localStorage.setItem('baseLang', JSON.stringify(baseLang));
	useEffect(() => {
		if (baseLang == 'zh') {
			setEnglish(false);
		}
	}, []);
	const tips = JSON.parse(localStorage.getItem('tips' || '{}'));
	const totalPrice = Math.abs(cart.reduce((a, b) => a + b.price1 * b.qty, 0) - tips);
	const totalSaved = cart.reduce((a, b) => a + b.price * b.qty, 0) - totalPrice;

	const [address, setAddress] = useState('');
	const [postcode, setPostcode] = useState('');
	const [num, setNum] = useState('1');
	// console.log('=> tableNumber', tableNumber);
	const [tel, setTel] = useState('');
	const [expMonth, setExpMonth] = useState('');
	const [expYear, setExpYear] = useState('');
	const [beizhu, setBeizhu] = useState('');
	const [cvv, setCvv] = useState(null);
	const [cardNumber, setCardNumber] = useState(null);
	// console.log('=> cardNumber', cardNumber);
	const [validDate, setValidDate] = useState('');
	const [nameOnCard, setNameOnCard] = useState(null);
	const orderContent = JSON.stringify(cart);
	const dineIn = localStorage.getItem('dineIn');
	const [access_token, setAccess_token] = useState('');
	const [userid, setUserid] = useState('');
	const [spinnerstatus, setSpinnerstatus] = useState(true);
	// console.log('dine in', dineIn);
	const handleCardNumber = (val) => {
		const inputValLength = val.length;
		val = val.replace(/[^\d]/g, '');
		const replacedValLength = val.length;
		if (inputValLength != replacedValLength) {
			alert(English ? 'Please enter digit numbers!' : '请在卡号栏输入数字！');
		}
		setCardNumber(val);
	};

	const handleValidDate = (val) => {
		if (val.length > validDate.length) {
			val = val.replace(/[^\d|^/]/g, '');
			if (val.length == 2) {
				val += '/';
			}
		}
		setValidDate(val);
	};

	const handleToPay = async (e) => {
		if (nameOnCard && cardNumber && validDate && cvv) {
			const data = await NewOrder(
				access_token,
				userid.toString(),
				res.id.toString(), //nescc
				res.types.toString(),
				orderContent.toString(), //nescc
				num.toString(), //nescc
				totalPrice.toString(), //nescc
				cardNumber.toString(), //nescc
				dineIn == 'true' ? tableNumber.toString() : 'Pick Up: ' + pickUpTime,
				nameOnCard, //nescc
				tel,
				address,
				postcode,
				validDate.split('/')[0].toString(), //nescc
				validDate.split('/')[1].toString(), //nescc
				cvv.toString(),
				beizhu
			);
			if (data && data.ret == 200 && data.data.paymentResult) {
				// console.log('success');
				// console.log('payerId:', data.data.id);
				localStorage.setItem('orderid', data.data.id);
				localStorage.setItem('payerId', JSON.stringify(data.data.id));
				history.push(`/thanks`);
			} else {
				// console.log('access_token:', access_token);
				// console.log('userid.toString():', userid.toString());
				// console.log('res.id.toString():', res.id.toString());
				// console.log('res.types:', res.types);
				// console.log('orderContent:', orderContent);
				// console.log('num.toString():', num.toString());
				// console.log('totalPrice.toString():', totalPrice.toString());
				// console.log('cardNumber.toString():', cardNumber.toString());
				// console.log('dineIn:', dineIn);
				// console.log('nameOnCard:', nameOnCard);
				// console.log('vaildDate:', validDate);
				// console.log('cvv:', cvv);
				history.push('/failure');
			}
		} else if (!nameOnCard) {
			setSpinnerstatus(true);
			alert(English ? 'Please enter your name!' : '请输入您的姓名！');
		} else if (!cardNumber) {
			setSpinnerstatus(true);
			alert(English ? 'Please enter your card number!' : '请输入您的卡号！');
		} else if (!validDate) {
			setSpinnerstatus(true);
			alert(English ? 'Please enter the expire date!' : '请输入有效日期！');
		} else if (!cvv) {
			setSpinnerstatus(true);
			alert(English ? 'Please enter cvv!' : '请输入cvv！');
		}
		// console.log("=> data", data);
		// }
	};

	const payWithDebounce = debounce(handleToPay, 3000);

	return (
		<>
			<div>
				<Navbar color='light' expand='lg' className='navbar-light osahan-nav shadow-sm'>
					<Container>
						<Navbar.Brand to='/'>
							<Row>
								<button
									style={{
										color: 'black',
										border: 'none',
										backgroundColor: 'transparent',
									}}
									onClick={() => history.push('../checkout')}>
									<RiArrowGoBackLine size='1.5rem' /> Go Back
								</button>
							</Row>
						</Navbar.Brand>
					</Container>
				</Navbar>
			</div>
			<div className='bg-white rounded shadow-sm p-4 osahan-payment'>
				<h6 className='mb-3 mt-0 mb-3'>{English ? 'Add new card' : '添加新卡'}</h6>
				<p>
					WE ACCEPT{' '}
					<span className='osahan-card'>
						<Icofont icon='visa-alt' size={40} /> <Icofont icon='mastercard-alt' size='40' />{' '}
						<Icofont icon='american-express-alt' size='40' /> <Icofont icon='paypal-alt' size='40' />{' '}
					</span>
				</p>

				<div className='form-row'>
					<Form.Group className='col-md-12'>
						<Form.Label>Card number</Form.Label>

						<InputGroup>
							<Form.Control
								type='text'
								placeholder='Card number'
								maxLength='16'
								value={cardNumber}
								onChange={(e) => handleCardNumber(e.target.value)}
							/>
						</InputGroup>
					</Form.Group>
					<Form.Group className='col-md-8'>
						<Form.Label>Expiry (MM/YY)</Form.Label>

						<Form.Control
							type='text'
							maxLength='5'
							placeholder='Enter Valid through(MM/YY)'
							value={validDate}
							//onKeyUp={(e) => formatString(e)}
							onChange={(e) => handleValidDate(e.target.value)}
						/>
					</Form.Group>
					<Form.Group className='col-md-4'>
						<Form.Label>CVC</Form.Label>
						<Form.Control
							type='number'
							placeholder='Enter CVC Number'
							maxLength='3'
							onChange={(e) => setCvv(e.target.value)}
						/>
					</Form.Group>
					<Form.Group className='col-md-12 mb-2'>
						<Form.Label>Name on card</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter name on the card'
							onChange={(e) => setNameOnCard(e.target.value)}
						/>
					</Form.Group>

					<button
						// to=""
						// to='/thanks'
						type='submit'
						className='btn btn-success btn-block btn-lg'
						style={{ display: 'block', width: '50%', margin: 'auto' }}
						onClick={() => (payWithDebounce(), setSpinnerstatus(!spinnerstatus))}>
						<Spinner
							as='span'
							variant='light'
							size='sm'
							role='status'
							aria-hidden='true'
							animation='border'
							hidden={spinnerstatus}
						/>
						PAY ${(totalPrice / 100).toFixed(2)}
						<Icofont icon='long-arrow-right' />
					</button>

					{/* <button
                            // to=""
                            // to='/thanks'
                            type='submit'
                            className='btn btn-success btn-block btn-lg'
                            onClick={payWithDebounce}>
                            PAY ${(totalPrice / 100).toFixed(2)}
                            <Icofont icon='long-arrow-right' />
                                </button> */}
				</div>
			</div>
		</>
	);
};

export default Cardpay;
