import { Col, Container, Image, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import React from "react";

class Failure extends React.Component {
	render() {
		//rule => /res=(id)&&type=()?t=(table number)

		const res = JSON.parse(localStorage.getItem("res"));
		const tableNumber = JSON.parse(localStorage.getItem("tableNumber"));
		const path = `/r/res=${res.id}&type=${res.types}?t=${tableNumber}`;
		return (
			<section className='section pt-5 pb-5 osahan-not-found-page'>
				<Container>
					<Row>
						<Col md={12} className='text-center pt-5 pb-5'>
							<Image
								className='img-fluid'
								src='/img/failure.png'
								alt='404'
							/>
							<h1 className='mt-2 mb-2'>
								Oops! Something went wrong...
							</h1>
							<p className='mb-5'>Try again please</p>
							<Link className='btn btn-primary btn-lg' to={path}>
								GO HOME
							</Link>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default Failure;
