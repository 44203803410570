import { AiOutlineDelete, AiOutlineShoppingCart } from 'react-icons/ai';
import {
	Badge,
	BarTop,
	BarWrapper,
	CartIcon,
	CartItem,
	CartListWrapper,
	CartWrapper,
	FoodListWrapper,
	FooterWrapper,
	NavItem,
	NavWrapper,
	OperateBtn,
	SettleButton,
	TableWrapper,
} from '../style';
import { Button, Col, Container, Image, Modal, Offcanvas, Row } from 'react-bootstrap';
import { FaMinusSquare, FaPlusSquare } from 'react-icons/fa';

import Box from '@mui/material/Box';
import { CSSTransition } from 'react-transition-group';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/MoveToInbox';
// import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import React from 'react';
import ReactModal from 'react-modal';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

ReactModal.setAppElement('#root');

const Bar = ({
	barIsVisible,
	toggleBar,
	cartList,
	English,
	cart,
	totalPrice,
	handleCheckout,
	addItem,
	delItem,
	footBar,
	barHeight,
}) => {
	return (
		<div>
			<SwipeableDrawer anchor='bottom' open={barIsVisible} onClose={toggleBar} onOpen={toggleBar}>
				<Row className='m-0 p-2 w-100 d-flex flex-column' style={{ height: '100%' }}>
					<BarTop className='d-flex w-100   justify-content-center pb-3 border-bottom'>
						<span className='title'>{English ? 'Selected Items' : '已选商品'}</span>
					</BarTop>
					<CartWrapper className='d-flex justify-content-center align-items-center flex-column'>
						{cartList &&
							Object.keys(cart).map((key) => (
								<Row
									className='mr-2 ml-2 w-100 '
									style={{ paddingTop: '10px', paddingBottom: '10px' }}
									key={key}>
									<Col md={1} xs={1} className='p-0 d-flex align-items-center justify-content-center'>
										<span className='account'> {cart[key]?.qty}</span>
									</Col>
									<Col md={6} xs={6} className=' d-flex align-items-start flex-column'>
										<span className='content'> {English ? cart[key].enname : cart[key].name}</span>
										{cart[key]?.selectedRules ? <p>{cart[key]?.selectedRules}</p> : ''}
									</Col>
									<Col md={5} xs={5} className=' d-flex align-items-center p-0 justify-content-end m'>
										<span className='price float-right mr-2'>
											${parseFloat((cart[key].price1 / 100).toFixed(2))}{' '}
										</span>
										<FaMinusSquare
											fontSize='1.8em'
											color='#00BFFF'
											onClick={() => delItem(cart[key])}
										/>

										<div className=' d-flex align-items-center h-100'>
											<input
												className='count-number-input  d-flex text-center '
												type='text'
												value={cart[key]?.qty}
												readOnly
											/>
										</div>
										<FaPlusSquare
											fontSize='1.8em'
											color='#00BFFF'
											onClick={() => addItem(cart[key])}
										/>
									</Col>
									<hr />
								</Row>
							))}
					</CartWrapper>
					{/* TODO: checkout 固定在底部 */}
					<Col
						className='mt-4 mb-4 m-0 w-100 h-100 d-flex flex-column align-items-end'
						style={{ height: '80px' }}>
						<div
							className='mt-4 mb-4 m-0 w-100 d-flex align-items-center pl-2 justify-content-between'
							style={{ height: '20px' }}>
							<span style={{ fontWeight: '500', fontSize: '15px' }}>Subtotal: </span>
							<span>${parseFloat((totalPrice / 100).toFixed(2))}</span>
						</div>
						<div className='mt-4 m-0 w-100 d-flex align-items-center justify-content-center'>
							<button
								className='btn btn-primary w-100 '
								style={{ height: '50px' }}
								onClick={handleCheckout}>
								Checkout
							</button>
						</div>
					</Col>
				</Row>
			</SwipeableDrawer>
			{/* <ReactModal
				isOpen={barIsVisible}
				contentLabel='onRequestClose Example'
				onRequestClose={toggleBar}
				className='Modal'
				overlayClassName='Overlay'
				style={{
					content: {
						border: '1px solid #ccc',
						background: '#fff',
						overflow: 'scroll',
						// borderRadius: '4px',
						// height: '100%',
						height: '500px',
						outline: 'none',
						zIndex: '9999',
					},
				}}>
				<Row className='m-0 p-0 w-100 d-flex flex-column' style={{ height: '100%' }}>
					<BarTop className='d-flex w-100   justify-content-center pb-3 border-bottom'>
						<span className='title'>{English ? 'Selected Items' : '已选商品'}</span>
					</BarTop>
					<CartWrapper className='d-flex justify-content-center align-items-center flex-column'>
						{cartList &&
							Object.keys(cart).map((key) => (
								<Row
									className='m-0 w-100 border-bottom'
									style={{ paddingTop: '10px', paddingBottom: '10px' }}
									key={key}>
									<Col md={1} xs={1} className='p-0 d-flex align-items-center justify-content-center'>
										<span className='account'> {cart[key]?.qty}</span>
									</Col>
									<Col md={8} xs={8} className=' d-flex align-items-start flex-column'>
										<span className='content'> {English ? cart[key].enname : cart[key].name}</span>
										{cart[key]?.selectedRules ? <p>{cart[key]?.selectedRules}</p> : ''}
									</Col>
									<Col md={3} xs={3} className=' d-flex align-items-center p-0 justify-content-end'>
										<span className='price float-right mr-2'>
											${parseFloat((cart[key].price1 / 100).toFixed(2))}{' '}
										</span>
										<FaMinusSquare
											fontSize='1.8em'
											color='#00BFFF'
											onClick={() => delItem(cart[key])}
										/>

										<div className=' d-flex align-items-center h-100'>
											<input
												className='count-number-input  d-flex text-center '
												type='text'
												value={cart[key]?.qty}
												readOnly
											/>
										</div>
										<FaPlusSquare
											fontSize='1.8em'
											color='#00BFFF'
											onClick={() => addItem(cart[key])}
										/>
									</Col>
									<hr />
								</Row>
							))}
					</CartWrapper>
					<Col
						className='mt-4 mb-4 m-0 w-100 h-100 d-flex flex-column align-items-end'
						style={{ height: '80px' }}>
						<div
							className='mt-4 mb-4 m-0 w-100 d-flex align-items-center pl-2 justify-content-between'
							style={{ height: '20px' }}>
							<span style={{ fontWeight: '500', fontSize: '15px' }}>Subtotal: </span>
							<span>${parseFloat((totalPrice / 100).toFixed(2))}</span>
						</div>
						<div className='mt-4 m-0 w-100 d-flex align-items-center justify-content-center'>
							<button
								className='btn btn-primary w-100 '
								style={{ height: '50px' }}
								onClick={handleCheckout}>
								Checkout
							</button>
						</div>
					</Col>
				</Row>
			</ReactModal> */}
		</div>
	);
};

export default Bar;
