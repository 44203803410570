// import 'bootstrap/dist/css/bootstrap.min.css';

import { Button, Card, Col, Container, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IdgetOrder } from '../api/API';
import { IoIosArrowForward } from 'react-icons/io';
import { Sendreceipt } from '../api/API';
import axios from 'axios';
import moment from 'moment';
import { resetCart } from './redux/action';

const Thanks = () => {
	const [English, setEnglish] = useState(true);
	const location = useLocation();
	const [spinnerstatus, setSpinnerstatus] = useState(true);
	var ual = navigator.language;
	var baseLang = 'zh';
	baseLang = ual ? ual.substring(0, 2).toLowerCase() : 'zh';

	const dispatch = useDispatch();

	useEffect(() => {
		if (baseLang == 'zh') {
			setEnglish(false);
		}
		dispatch(resetCart());
	}, []);

	// 	// 英文
	// } else {
	// 	// 中文
	// }
	// console.log('=> location', location);
	const [order, setOrder] = useState([]);
	const [total, setTotal] = useState([]);

	const res = JSON.parse(localStorage.getItem('res'));
	// console.log('=> res', res);
	const tableNumber = JSON.parse(localStorage.getItem('tableNumber'));
	const path = `/r/res=${res.id}&type=${res.types}?t=${tableNumber}`;
	const orderid = localStorage.getItem('orderid');
	const payerID = JSON.parse(localStorage.getItem('payerId' || {}));
	const pickUpTime = useSelector((state) => state.time);
	const dineIn = localStorage.getItem('dineIn');
	useEffect(() => {
		handleStatus();
	}, []);
	const handleStatus = async () => {
		//TODO order: 127 should be payerID
		if (payerID || orderid) {
			// console.log('=> payerID', payerID);
			const data = await IdgetOrder('', payerID ? payerID : orderid);
			// console.log('=> data', data);
			setTotal(data.data.total);
			setOrder(JSON.parse(data.data.ordercontent));
		}
	};
	const name = localStorage.getItem('cusName');

	// console.log('=> name', name);
	// send Email
	const [show, setShow] = useState(false);
	const handleClick = () => {
		setShow(true);
	};
	const [email, setEmail] = useState('');
	const handleSend = async () => {
		console.log('orderid:', orderid);
		console.log('emial:', email);
		const data = await Sendreceipt('', email, orderid);
		if (data && data.ret === 200) {
			setShow(false);
			alert('Reciept has been successfully sent!Please check your junk mail!');
		} else {
			alert('Error,please enter the correct email.');
		}
	};

	// console.log('>>>>>order', order);
	// console.log('>>>>>total', total);
	return (
		<section className='section pt-5 pb-5 osahan-not-found-page'>
			<Row className='d-flex justify-content-center flex-column'>
				<Col>
					<h6 className='text-center'>{English ? res.nameen : res.name}</h6>
				</Col>
				<Col>
					<h6 className='text-center'>
						{dineIn == 'true' ? 'Table: ' + tableNumber.toString() : 'Pick Up: ' + pickUpTime}
					</h6>
				</Col>
			</Row>
			<Container>
				<Row>
					<Col md={12} className='pt-5 pb-5'>
						<Card
							className='shadow p-3 mb-5 bg-white  border-0'
							style={{ borderRadius: '10px' }}
							onClick={handleClick}>
							<Card.Title className='d-flex justify-content-between' style={{ margin: '0' }}>
								Request email receipt
								<IoIosArrowForward size='20px' />
							</Card.Title>
						</Card>
						<Modal show={show} onHide={() => setShow(false)}>
							<Modal.Header>
								<Modal.Title>Your email:</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form>
									<Form.Group className='mb-3' controlId='formBasicEmail'>
										<Form.Label>Email address</Form.Label>
										<Form.Control
											type='email'
											placeholder='Enter email'
											required
											onChange={(e) => setEmail(e.target.value)}
										/>
										<Form.Text className='text-muted'>
											We'll never share your email with anyone else.
										</Form.Text>
									</Form.Group>
									<Row className='d-flex justify-content-center'>
										<Button variant='primary' onClick={handleSend}>
											Send
										</Button>
									</Row>
								</Form>
							</Modal.Body>
							{/* <Modal.Footer>
								
								<Button variant='primary' onClick={handleSend}>
									Send
								</Button>
							</Modal.Footer> */}
						</Modal>
						<Card className='shadow p-3 mb-5 bg-white  border-0' style={{ borderRadius: '10px' }}>
							<Card.Title>
								{English ? 'You have successfully paid' : '恭喜您成功付款'} ${' '}
								{parseFloat((total / 100).toFixed(2))}
							</Card.Title>
							<p className='font-weight-bold text-black font-size-16' style={{ fontSize: '14px' }}>
								{English ? ' My Order:' : '我的订单：'}
							</p>
							<ol className='font-weight-bold'>
								{order.length > 0 &&
									order.map((item) => (
										<li>
											<Row className='d-flex flex-row ml-2'>
												<Col xs={10} md={10} sm={10} className='pl-0'>
													<p
														className='font-weight-bold text-black'
														style={{ fontSize: '14px' }}>
														{item.name} x {item.qty}
													</p>
												</Col>
												<Col xs={2} md={2} sm={2} className='p-0'>
													<p
														className='font-weight-bold text-black '
														style={{ fontSize: '14px', marginBottom: '0' }}>
														$ {parseFloat((item.price1 / 100).toFixed(2))}
													</p>
												</Col>
											</Row>
										</li>
									))}
							</ol>
						</Card>
						<Card className='shadow p-3 bg-white  border-0' style={{ borderRadius: '10px' }}>
							<p className='text-black text-center' style={{ fontSize: '12px', marginBottom: '0' }}>
								{English
									? 'If you have questions about your order, please reach out to the venue.'
									: '如果对您的下单有疑问，请询问商家。'}
							</p>
						</Card>
						{/* <Image className='img-fluid' src='/img/thanks.png' alt='404' />
						<h1 className='mt-2 mb-2'>Congratulations</h1>
						<p className='mb-5'>We have successfully placed your order</p> */}
						<Row className='d-flex justify-content-center'>
							<Link
								className='btn btn-primary btn-lg mt-3'
								to={path}
								onClick={() => setSpinnerstatus(!spinnerstatus)}>
								<Spinner
									as='span'
									variant='light'
									size='sm'
									role='status'
									aria-hidden='true'
									animation='border'
									hidden={spinnerstatus}
								/>
								{English ? 'BACK HOME' : '返回主页'}
							</Link>
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
	// }
};

export default Thanks;
