import { Button, Col, Container, Image, Modal, Offcanvas, Row } from 'react-bootstrap';

import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import ReactModal from 'react-modal';
import Toolbar from '@mui/material/Toolbar';

ReactModal.setAppElement('#root');

const TipsModel = ({
	showOffcanvas,
	handleAddTips,
	English,
	handleSelectedTips,
	totalPrice,
	selectedIndex,
	handleCloseOffcanvas,
	handleNextTime,
}) => {
	// tips section
	const tipsOptions = [
		{
			percentage: '5',
			variant: 'first',
		},
		{
			percentage: '10',
			variant: 'second',
		},
		{
			percentage: '15',
			variant: 'third',
		},
		{
			percentage: '20',
			variant: 'fourth',
		},
	];
	return (
		<div>
			<ReactModal
				isOpen={showOffcanvas}
				contentLabel='onRequestClose Example'
				// onRequestClose={handleCloseOffcanvas}
				className='Modal-tips'
				overlayClassName='Overlay'>
				<AppBar sx={{ position: 'relative', backgroundColor: ' #fff', boxShadow: 'none' }}>
					<Toolbar>
						<IconButton
							edge='start'
							sx={{ color: 'gray' }}
							onClick={handleCloseOffcanvas}
							aria-label='close'>
							<CloseIcon />
						</IconButton>
						<Row className='w-100 d-flex align-items-center justify-content-center'>
							<h5 style={{ fontWeight: '700' }}>
								{English ? 'Say thanks with a tips ❤️' : '要给小费么？❤️'}
							</h5>
						</Row>
					</Toolbar>
				</AppBar>

				<Row className='w-100 m-0 d-flex align-items-center justify-content-center' style={{ height: '55px' }}>
					{tipsOptions.map((item, index) => (
						<Col className=' ml-1 mr-1 h-100 p-0 text-center ' key={item.percentage}>
							<Button
								className={
									selectedIndex == index
										? 'tips-percent-select w-100 p-0 m-0 h-100'
										: 'tips-percent w-100 p-0 m-0 h-100'
								}
								onClick={() =>
									handleSelectedTips(
										item.percentage,
										index,
										parseFloat((totalPrice * (item.percentage / 100)).toFixed(2))
									)
								}>
								<span className='font-weight-bold'>{item.percentage}%</span>
								<span className='font-weight-bold' style={{ fontSize: '10px' }}>
									${parseFloat(((totalPrice * (item.percentage / 100)) / 100).toFixed(2))}
								</span>
							</Button>
						</Col>
					))}
				</Row>
				<Row className='w-100 mt-2 ml-0 mr-0 d-flex align-items-center justify-content-center'>
					<Col className=' ml-1 mr-1 pl-0 pr-0 h-100 text-center'>
						<Button className='w-100 tips-btn' onClick={handleNextTime}>
							{English ? 'Maybe next time' : '下次再说'}
						</Button>
					</Col>
				</Row>
				<Row className='w-100 mt-2 ml-0 mr-0 d-flex align-items-center justify-content-center'>
					<Col className=' ml-1 mr-1 pl-0 pr-0 h-100 text-center'>
						<Button className='w-100' style={{ borderRadius: '4px' }} onClick={handleAddTips}>
							{English ? 'Add tip and pay' : '添加小费并支付'}
						</Button>
					</Col>
				</Row>
				{/* <button onClick={handleCloseOffcanvas}>Close Modal</button> */}
			</ReactModal>
		</div>
	);
};

export default TipsModel;
