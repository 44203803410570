import 'react-credit-cards/es/styles-compiled.css';
import { Container,Navbar, Row} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Icofont from 'react-icofont';
import { useHistory } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';

const Addcard = () =>{
    
    const res = JSON.parse(localStorage.getItem('res'));
    const [tableNumber, setTableNumber] = useState(
		localStorage.getItem('tableNumber') ? JSON.parse(localStorage.getItem('tableNumber')) : 0
	);
	const history = useHistory();
	const [English, setEnglish] = useState(true);
	var ual = navigator.language;
	var baseLang = 'zh';
	baseLang = ual ? ual.substring(0, 2).toLowerCase() : 'zh';
	// localStorage.setItem('baseLang', JSON.stringify(baseLang));
	useEffect(() => {
		if (baseLang == 'zh') {
			setEnglish(false);
		}
	}, []);
    const handleAddCard = () => {
        history.push('../cardpay');
    }

    return(
        
        <>
            <div>
				<Navbar color='light' expand='lg' className='navbar-light osahan-nav shadow-sm'>
					<Container>
						<Navbar.Brand to='/'>
							<Row>
								<button
									style={{
										color: 'black',
										border: 'none',
										backgroundColor: 'transparent',
									}}
									onClick={() => history.push(`/r/res=${res.id}&type=${res.types}?t=${tableNumber}`)}>
									<RiArrowGoBackLine size='1.5rem' /> Go Back
								</button>
							</Row>
						</Navbar.Brand>
					</Container>
				</Navbar>
			</div>
            <div className='bg-white rounded shadow-sm p-4 add-card'>
                
                <h6 className='mb-3 mt-0 mb-3'>
                    {English ? 'Credit Card payment' : '银行卡支付'}
                </h6>

                <div style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}} className = 'oshan-card-2'>
                    <Icofont icon='mastercard-alt' size="40"/>
                    <span>{'*********'}</span> 
                </div>

                <button
                style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}
                className='btn'
                onClick={handleAddCard}>
                    <Icofont icon='ui-add' />
                    <span>{'Add a new card'}</span> 
                </button>

                    
                    {/* <button
                        // to=""
                        // to='/thanks'
                        type='submit'
                        className='btn btn-success btn-block btn-lg'
                        onClick={payWithDebounce}>
                        PAY ${(totalPrice / 100).toFixed(2)}
                        <Icofont icon='long-arrow-right' />
                            </button> */}
                </div>

        </>
    )
}

export default Addcard;