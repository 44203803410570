import 'rodal/lib/rodal.css';

import { AliveScope, KeepAlive, useActivate } from 'react-activation';
import {
	Badge,
	BarTop,
	BarWrapper,
	CartIcon,
	CartItem,
	CartWrapper,
	FoodListWrapper,
	FooterWrapper,
	NavItem,
	NavWrapper,
	OperateBtn,
	PayInfo,
	SettleButton,
	TableWrapper,
} from '../style';
import { Button, Card, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { FaMinusSquare, FaPlusSquare } from 'react-icons/fa';
import React, { useEffect, useRef, useState } from 'react';

import AddRules from './AddRules';
import { ImageViewer } from '../viewer/ImageViewer';
import Rodal from 'rodal';

const FoodSection = ({
	navPosition,
	foodListHeight,
	courseType,
	activeIndex,
	English,
	handleSelect,
	foodListRef,
	course,
	delItem,
	addItem,
	cart,
	showBadge,
	history,
}) => {
	const [show, setShow] = useState(false);
	const [details, setDetails] = useState([]);
	const [clickedIndex, setClickedIndex] = useState('');
	const [changedIndex, setChangedIndex] = useState('');
	let ref = useRef();
	const [rule1, setRule1] = useState([]);
	const [rule1en, setRule1en] = useState([]);
	const [rule2, setRule2] = useState([]);
	const [rule2en, setRule2en] = useState([]);
	const [rule3en, setRule3en] = useState([]);
	const [firstValue, setFirstValue] = useState([]);
	const [secondValue, setSecondValue] = useState([]);
	const [thirdValue, setThirdValue] = useState([]);

	const handleAddRules = () => {
		let addRules = '';
		if (firstValue.length > 0) {
			addRules = addRules.concat(firstValue);
			if (secondValue.length > 0) {
				addRules = addRules.concat(',', secondValue);
				if (thirdValue.length > 0) {
					addRules = addRules.concat(',', thirdValue);
				}
			}
		}
		const newDetails = [details].map((i) => ({
			...i,
			selectedRules: addRules,
		}));
		addItem(...newDetails);
		handleClose();
		setFirstValue('');
		setSecondValue('');
		setThirdValue('');
	};

	useEffect(() => {
		setClickedIndex(clickedIndex);
	}, [clickedIndex]);

	const handleShow = (item, index) => {
		if (clickedIndex == index) {
			setDetails(item);
			setShow(true);
			setClickedIndex('');
		}
		if (index == undefined) {
			setDetails(item);
			setShow(true);
		}

		// }
	};

	const handleClose = () => {
		setShow(false);
		// setDetails([]);
	};

	const handleDetail = (item) => {
		localStorage.setItem('detial', JSON.stringify(item));
		history.push('../coursedetail');
	};
	// console.log('=> cart', courses);
	return (
		<div>
			<NavWrapper className={navPosition} resetHeight={foodListHeight}>
				{courseType.map((e, index) => (
					<NavItem
						className={activeIndex === index ? 'active' : ''}
						key={index}
						onClick={() => handleSelect(index)}>
						<span>{English ? e.enname : e.cnname}</span>
						{/* TODO: add badge to the side menu 下滑到对应菜品分类的选项默认为选中状态*/}
						{/* <div>{showBadge()}</div> */}
					</NavItem>
				))}
			</NavWrapper>
			<FoodListWrapper ref={foodListRef}>
				{courseType &&
					courseType.map((item, index) => (
						<div className='w-100 rounded  shadow-sm' key={item.id} id={item.enname}>
							<div style={{ height: '30px', marginBotto: '10px', paddingTop: '5px' }}>
								<h5 className='col-md-12 mb-0'>{English ? item.enname : item.cnname} </h5>
							</div>
							{Array.isArray(course) &&
								course.length > 0 &&
								course
									.filter((x) => x.coursetypeid == item.id)
									.map((item, index) => (
										<Row
											className='pt-3 pb-3 pl-3 w-100'
											style={{
												padding: '0px',
											}}
											key={item.id}>
											<Col
												md={12}
												xs={12}
												style={{
													paddingRight: '0px',
												}}
												name='clickableDiv'
												onClick={(e) => {
													// console.log('Col', e);
													// setClickedIndex(item.id);
													handleDetail(item);
													// handleShow(item, item.id);
													// history.push('../coursedetail');
												}}>
												<div
													className='d-flex'
													style={{
														height: '70px',
													}}>
													<div
														className='flex-shrink-0 align-items-center justify-content-center mr-1'
														style={{
															width: '70px',
														}}>
														{/* <ImageViewer className='w-100 h-100 view'> */}
														<img
															src={item.pic1 ? item.pic1 : '/img/5.jpg'}
															className='w-100 h-100'
															alt='Your image'
														/>
														{/* </ImageViewer> */}
													</div>
													<div className='flex-grow-1 ms-3'>
														<h6 className='mb-8'>{English ? item.enname : item.name} </h6>
														<Row className='w-100 mt-2'>
															<Col className='d-flex flex-row '>
																<div
																	className=' d-flex flex-row'
																	style={{
																		paddingRight: '0',
																		alignItems: 'center',
																	}}>
																	<p
																		className='text-gray mb-0 '
																		style={{
																			// color: 'red',
																			color: 'black',
																		}}>
																		${parseFloat((item.price1 / 100).toFixed(2))}{' '}
																		{/* TODO:Login show different price */}
																		{/* <del
																			style={{
																				color: 'gray',
																			}}>
																			${parseFloat((item.price / 100).toFixed(2))}
																		</del> */}
																	</p>
																</div>
															</Col>

															<Col
																className='d-flex p-0 flex-row justify-content-end'
																style={{
																	bottom: '0',
																}}>
																<Row
																	className=' d-flex justify-content-end '
																	style={{
																		padding: '0 5px 0 0',
																		bottom: '0',
																	}}>
																	{item.instock == 1 ? (
																		item.rule1 ? (
																			<>
																				<button
																					className='rule'
																					name=''
																					onClick={(e) => {
																						handleDetail(item);
																						// handleShow(item);
																						// history.push('../coursedetail');
																					}}>
																					<span>
																						{English
																							? 'Customise'
																							: '选规格'}
																					</span>
																				</button>
																				{/* <AddRules
																					details={details}
																					show={show}
																					handleClose={handleClose}
																					English={English}
																					firstValue={firstValue}
																					secondValue={secondValue}
																					thirdValue={thirdValue}
																					setFirstValue={setFirstValue}
																					setSecondValue={setSecondValue}
																					setThirdValue={setThirdValue}
																					handleAddRules={handleAddRules}
																				/> */}
																			</>
																		) : (
																			<>
																				<FaMinusSquare
																					fontSize='1.8em'
																					color='#00BFFF'
																					style={{ zIndex: '99' }}
																					onClick={(e) => {
																						e.stopPropagation();
																						delItem(item);
																					}}
																				/>

																				<div className=' d-flex align-items-center h-100'>
																					<input
																						className='count-number-input  d-flex text-center '
																						type='text'
																						value={
																							cart.find(
																								(x) => x.id === item.id
																							)
																								? cart.find(
																										(x) =>
																											x.id ===
																											item.id
																								  ).qty
																								: 0
																						}
																						readOnly
																					/>
																				</div>
																				<FaPlusSquare
																					fontSize='1.8em'
																					color='#00BFFF'
																					// style={{ zIndex: '99' }}
																					onClick={(e) => {
																						e.stopPropagation();
																						addItem(item);
																					}}
																				/>
																			</>
																		)
																	) : English ? (
																		'out of stock'
																	) : (
																		'缺货'
																	)}
																</Row>
															</Col>
														</Row>
													</div>
												</div>
											</Col>
										</Row>
									))}
						</div>
					))}
			</FoodListWrapper>
		</div>
	);
};

export default FoodSection;
