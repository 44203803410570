import styled from 'styled-components';

export const TableWrapper = styled.div`
	background-color: #fff;
	width: 100%;
	box-sizing: border-box;
	padding: 10px;
	justify-content: center;
	align-items: center;
	z-index: 10;
	border-bottom: 1px solid #ededed;
	&.fixed {
		position: fixed;
		top: 615px;
		height: 50px;
	}
`;
export const NavWrapper = styled.div`
	display: block;
	width: 75px;
	background-color: #ededed;
	box-sizing: border-box;
	&.move {
		float: left;
		height: ${(props) => props.resetHeight + 'px'};
	}
	&.fixed {
		position: fixed;
		top: 0;
		left: 0;
		height: calc(100%);
	}
`;

export const NavItem = styled.div`
	display: block;
	// height: 36px;
	// line-height: 36px;
	text-align: center;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 14px;
	&.active {
		background-color: white;
	}
`;

export const FoodListWrapper = styled.div`
	width: 100%;
	/* height: calc(100% - 60px); */
	padding-top: 5px;
	padding-right: 15px;
	padding-left: 85px;
	box-sizing: border-box;
	background-color: white;
	margin-bottom: 60px;
`;
export const BarWrapper = styled.div`
	position: fixed;
	width: 100%;
	max-height: 80%;
	overflow: scroll;
	line-height: 16px;
	padding: 10px;
	box-sizing: border-box;
	background-color: whitesmoke;
`;

export const BarTop = styled.div`
	height: 45px;
	.title {
		/* width: 100px; */
		font-size: 16px;
		color: black;
	}
	.deleteIcon {
		float: right;
	}
	.deleteText {
		float: right;
		margin-right: 16px;
		line-height: 18px;
		font-size: 14px;
		font-weight: 200;
	}
`;

export const CartWrapper = styled.ul`
	width: 100%;
	margin-bottom: 0;
	padding: 0px;
	// border-bottom: 0.5px solid #fff;
	.account {
		font-size: 14px;
		font-weight: 400;
		margin: 0;
		text-align: center;
		padding: 0;
	}
	.content {
		font-size: 14px;
		font-weight: 600;
		margin: 0;
		text-align: center;
		padding: 0;
	}
	.price {
		font-size: 14px;
		font-weight: 500;
		margin: 0;
		padding: 0;
	}
`;
export const CartListWrapper = styled.div`
	width: 100%;
	padding: 0px;
`;

export const CartItem = styled.li`
	position: relative;
	display: block;
	height: 42px;
	margin-bottom: 10px;
	padding: 15px;
	font-size: 14px;
	img {
		display: block;
		float: left;
		width: 64px;
		margin-right: 12px;
		border-radius: 3px;
	}
	.name {
		margin-bottom: 8px;
	}
`;

export const OperateBtn = styled.div`
	position: absolute;
	bottom: 6px;
	right: 15px;
	.account {
		font-size: 14px;
		margin: 0 8px;
	}
	.btn {
		display: inline-block;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		font-size: 16px;
		font-weight: bolder;
		border-radius: 11px;
	}
	.add {
		color: white;
		background-color: #00bfff;
		border: 0.5px solid whitesmoke;
	}
	.sub {
		color: #00bfff;
		background-color: white;
		border: 0.5px solid #00bfff;
	}
`;

export const FooterWrapper = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 60px;
	box-sizing: border-box;
	background-color: transparent;
	z-index: 30;
`;

export const CartIcon = styled.div`
	display: block;
	position: relative;
	float: left;
	width: 40px;
	margin-top: 3px;
`;

export const Badge = styled.div`
	// display: fixed;
	position: absolute;
	top: 0px;
	right: 0;
	width: 16px;
	height: 16px;
	line-height: 16px;
	text-align: center;
	font-size: 12px;
	border-radius: 8px;
	color: white;
	background-color: #f50;
	z-index: 1000;
`;

export const ViewCart = styled.div`
	display: block;
	.view {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 60px;
		font-size: 17px;
		font-weight: 600;
		background-color: #00bfff;
		color: white;
		border: none;
	}
	// .delivery {
	// 	display: inline-block;
	// 	margin-left: 5px;
	// 	font-size: 11px;
	// 	font-weight: 200;
	// }
`;

export const SettleButton = styled.button`
	float: right;
	width: 96px;
	height: 38px;
	margin-top: 2px;
	border-radius: 19px;
	border: 0;
	background-color: ${(props) => (props.fill > 0 ? '#00BFFF' : '#777')};
	color: white;
	font-size: 14px;
`;
