import axios from 'axios';

// const ieatURL = 'https://api.ieats.com.au/api/';
const ieatURL = ' https://ios.outz.com.au/api/';
//店铺ID获取菜品规格信息
//App.Courserule.ResidgetCourserule
export var ResidgetCourserule = async (access_token, resid, servicetype) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('resid', resid);
	formData.append('servicetype', servicetype);

	let result = await axios({
		method: 'post',
		url: ieatURL + 'admin.php?s=App.Courserule.ResidgetCourserule',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//店铺ID获取菜品分类和菜品信息
//App.Course.ResidgetCourseortype
export var ResidgetCourseortype = async (access_token, resid, servicetype) => {
	let formData = new FormData();
	formData.append('', access_token);
	formData.append('resid', resid);
	formData.append('servicetype', servicetype);

	let result = await axios({
		method: 'post',
		url: ieatURL + 'admin.php?s=App.Course.ResidgetCourseortype',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

// //添加订单接口
// //App.Order.NewOrder
// export var NewOrder = async (
// 	access_token,
// 	userid,
// 	resid,
// 	servicetype,
// 	ordercontent,
// 	num,
// 	total,
// 	number,
// 	tablenumber,
// 	name,
// 	tel,
// 	beizhu
// ) => {
// 	let formData = new FormData();
// 	formData.append("access_token", access_token);
// 	formData.append("userid", userid);
// 	formData.append("resid", resid);
// 	formData.append("ordercontent", ordercontent);
// 	formData.append("servicetype", servicetype);
// 	formData.append("tablenumber", tablenumber);
// 	formData.append("num", num);
// 	formData.append("total", total);
// 	formData.append("number", number);
// 	formData.append("name", name);
// 	formData.append("tel", tel);
// 	formData.append("beizhu", beizhu);

// 	let result = await axios({
// 		method: "post",
// 		url: ieatURL + "app.php?s=App.Order.NewOrder",
// 		data: formData,
// 		config: { headers: { "Content-Type": "multipart/form-data" } },
// 	})
// 		.then(function (response) {
// 			return response.data;
// 		})
// 		.catch(function (response) {});
// 	//console.log(result);
// 	return result;
// };

//添加新的银行卡
//App.Payment.NewCard
export var NewCard = async (access_token, resid, servicetype) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('resid', resid);
	formData.append('servicetype', servicetype);

	let result = await axios({
		method: 'post',
		url: ieatURL + 'app.php?s=App.Payment.NewCard',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//订单ID获订单信息
//App.Order.IdgetOrder

//id获取餐厅信息
//App.Restaurant.IdgetRestaurant
export var IdgetRestaurant = async (access_token, id) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('id', id);

	let result = await axios({
		method: 'post',
		url: ieatURL + 'app.php?s=App.Restaurant.IdgetRestaurant',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

export var WechatPay = async (resid, amount, description, summary, num, tablenumber) => {
	let formData = new FormData();
	formData.append('service', 'App.Payment.QrcodePay');
	formData.append('access_token', '');
	formData.append('resid', resid);
	formData.append('amount', amount);
	formData.append('description', description);
	formData.append('summary', summary);
	formData.append('num', num);
	formData.append('tablenumber', tablenumber);

	let result = await axios({
		method: 'post',
		url: ieatURL + 'app.php?s=App.Payment.QrcodePay',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//添加订单接口
//App.Order.NewOrder
export var NewOrder = async (
	access_token,
	userid,
	resid,
	servicetype,
	ordercontent, //订单详细信息，存json
	num, //订单数量
	total,
	number,
	tablenumber,
	name,
	tel,
	address,
	postcode,
	exp_month,
	exp_year,
	cvc,
	beizhu
) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('userid', userid);
	formData.append('resid', resid);
	formData.append('servicetype', servicetype);
	formData.append('ordercontent', ordercontent);
	formData.append('num', num);
	formData.append('total', total);
	formData.append('number', number);
	formData.append('tablenumber', tablenumber);
	formData.append('name', name);
	formData.append('tel', tel);
	formData.append('address', address);
	formData.append('postcode', postcode);
	formData.append('exp_month', exp_month);
	formData.append('exp_year', exp_year);
	formData.append('cvc', cvc);
	formData.append('beizhu', beizhu);

	let result = await axios({
		method: 'post',
		url: 'https://ios.outz.com.au/api/app.php?s=App.Order.NewOrder',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//添加新的Paypal订单接口
//App.Order.NewPaypalOrder
export var NewPaypalOrder = async (
	access_token,
	userid,
	resid,
	servicetype,
	ordercontent, //订单详细信息，存json
	num, //订单数量
	total,
	number,
	tablenumber,
	name,
	tel,
	address,
	postcode,
	paymentID,
	beizhu,
	paypaltoken
) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('userid', userid);
	formData.append('resid', resid);
	formData.append('servicetype', servicetype);
	formData.append('ordercontent', ordercontent);
	formData.append('num', num);
	formData.append('total', total);
	formData.append('number', number);
	formData.append('tablenumber', tablenumber);
	formData.append('name', name);
	formData.append('tel', tel);
	formData.append('address', address);
	formData.append('postcode', postcode);
	formData.append('paymentID', paymentID);
	formData.append('beizhu', beizhu);
	formData.append('paypaltoken', paypaltoken);

	let result = await axios({
		method: 'post',
		url: 'https://ios.outz.com.au/api/app.php?s=App.Order.NewPaypalOrder',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//店铺ID获取营业时间信息
//App.Openhour.ResidgetOpenhour
export var ResidgetOpenhour = async (access_token, resid, servicetype) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('resid', resid);
	formData.append('servicetype', servicetype);

	let result = await axios({
		method: 'post',
		url: ieatURL + 'app.php?s=App.Openhour.ResidgetOpenhour',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//订单ID获订单信息
//App.Order.IdgetOrder
export var IdgetOrder = async (access_token, id) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('id', id);

	let result = await axios({
		method: 'post',
		url: 'https://ios.outz.com.au/api/app.php?s=App.Order.IdgetOrder',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//发送小票邮件接口
//App.Email.Sendreceipt
export var Sendreceipt = async (access_token, email, orderid) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('email', email);
	formData.append('orderid', orderid);

	let result = await axios({
		method: 'post',
		url: 'https://ios.outz.com.au/api/app.php?s=App.Email.Sendreceipt',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//会员邮箱注册接口
//App.User.EmailRegister
export var EmailRegister = async (access_token, password, email, nickname, sex, mobile, state, city) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('email', email);
	formData.append('password', password);
	formData.append('nickname', nickname);
	formData.append('sex', sex);
	formData.append('mobile', mobile);
	formData.append('state', state);
	formData.append('city', city);

	let result = await axios({
		method: 'post',
		url: 'https://ios.outz.com.au/api/app.php?s=App.User.EmailRegister',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};

//用户邮箱登录
//App.User.EmailLogin
export var EmailLogin = async (access_token, email, password) => {
	let formData = new FormData();
	formData.append('access_token', access_token);
	formData.append('email', email);
	formData.append('password', password);

	let result = await axios({
		method: 'post',
		url: 'https://ios.outz.com.au/api/app.php?s=App.User.EmailLogin',
		data: formData,
		config: { headers: { 'Content-Type': 'multipart/form-data' } },
	})
		.then(function (response) {
			return response.data;
		})
		.catch(function (response) {});
	//console.log(result);
	return result;
};
