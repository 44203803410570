import cart from './cart';
import { combineReducers } from 'redux';
import redListState from './redListState';
import takeaway from './takeaway';
import time from './time';

export default combineReducers({
	cart,
	takeaway,
	time,
	redListState,
});
