import { Button, Card, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { FaMinusSquare, FaPlusSquare } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { addCart, delCart, updateCart } from './redux/action';
import { useDispatch, useSelector } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button as MuiButton } from '@mui/material/';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const CourseDetail = () => {
	const detail = JSON.parse(localStorage.getItem('detial'));
	const [English, setEnglish] = useState(true);
	const [firstValue, setFirstValue] = useState('');
	const [secondValue, setSecondValue] = useState('');
	const [thirdValue, setThirdValue] = useState('');
	const history = useHistory();
	const cart = useSelector((state) => state.cart);

	useEffect(() => {
		if (history.action === 'POP') {
			history.goBack();
		}
	}, [history.action]);

	const handleGoBack = () => {
		history.goBack();
	};
	var ual = navigator.language;
	var baseLang = 'zh';
	baseLang = ual ? ual.substring(0, 2).toLowerCase() : 'zh';
	// localStorage.setItem('baseLang', JSON.stringify(baseLang));
	useEffect(() => {
		if (baseLang == 'zh') {
			setEnglish(false);
		}
	}, []);
	// console.log('detail', detail);

	const [count, setCount] = useState([
		cart.find((x) => x.id === detail.id)?.qty
			? { ...cart.find((x) => x.id === detail.id) }
			: {
					...detail,
					qty: 1,
			  },
	]);
	const [scroll, setScroll] = useState('paper');

	const addCount = (item) => {
		setCount(
			detail.qty
				? detail.map((x) =>
						x.id === item.id && x?.selectedRules == item.selectedRules ? { ...x, qty: x.qty + 1 } : x
				  )
				: count.map((x) =>
						x.id === item.id && x?.selectedRules == item.selectedRules ? { ...x, qty: x.qty + 1 } : x
				  )
		);

		// console.log('=> count', count);
	};
	// console.log('=> count', count);

	const minusCount = (item) => {
		setCount(count.map((x) => (x.id === item.id ? { ...x, qty: x.qty - 1 > 1 ? x.qty - 1 : 1 } : x)));
	};
	const dispatch = useDispatch();

	// Action to ADD/DEL item to/from cart list
	const addItem = (item) => {
		dispatch(addCart(item));
	};
	const delItem = (item) => {
		dispatch(delCart(item));
	};
	// Fix detail page add to cart issue
	const updateItem = (item) => {
		dispatch(updateCart(item));
	};

	window.alert = function (name) {
		var iframe = document.createElement('IFRAME');
		iframe.style.display = 'none';
		iframe.setAttribute('src', 'data:text/plain,');
		document.documentElement.appendChild(iframe);
		window.frames[0].window.alert(name);
		iframe.parentNode.removeChild(iframe);
	};

	const handleAddRules = () => {
		if (JSON.parse(detail?.rulename1)?.cnname) {
			if (firstValue) {
				if (JSON.parse(detail?.rulename2)?.cnname) {
					if (secondValue) {
						if (JSON.parse(detail?.rulename3)?.cnname) {
							if (thirdValue) {
								let addRules = '';
								if (firstValue.length > 0) {
									addRules = addRules.concat(firstValue);
									if (secondValue.length > 0) {
										addRules = addRules.concat(',', secondValue);
										if (thirdValue.length > 0) {
											addRules = addRules.concat(',', thirdValue);
										}
									}
								}
								const newDetails = [...count].map((i) => ({
									...i,
									selectedRules: addRules,
								}));
								// console.log('=> newDetails', newDetails);
								updateItem(...newDetails);
								// handleClose();
								setFirstValue('');
								setSecondValue('');
								setThirdValue('');
								history.goBack();
							} else {
								// console.log('3');
								window.alert(
									English
										? `Please select ${JSON.parse(detail?.rulename3)?.enname}`
										: `请选择${JSON.parse(detail?.rulename3)?.cnname}`
								);
							}
						}
					} else {
						// console.log('2');

						window.alert(
							English
								? `Please select ${JSON.parse(detail?.rulename2)?.enname}`
								: `请选择${JSON.parse(detail?.rulename2)?.cnname}`
						);
					}
				}
			} else {
				// console.log('1');

				window.alert(
					English
						? `Please select ${JSON.parse(detail?.rulename1)?.enname}`
						: `请选择${JSON.parse(detail?.rulename1)?.cnname}`
				);
			}
		} else {
			updateItem(...count);
			// console.log('=> !!!!!!!!!!!!!count', count);
			history.goBack();
		}
	};

	return (
		<Dialog fullScreen open={true}>
			<AppBar sx={{ background: `linear-gradient(rgba(0,0,0,0.2), transparent)`, boxShadow: 'none' }}>
				<Toolbar>
					<IconButton
						edge='start'
						className='close-btn '
						sx={{ margin: '0', position: 'fixed' }}
						// sx={{ display: 'flex', color: 'black', position: 'absolute' }}
						onClick={handleGoBack}
						aria-label='close'>
						<ArrowBackIosIcon sx={{ color: '#fff', float: 'right' }} />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent dividers={scroll === 'paper'} sx={{ padding: '0' }}>
				<Container className='detail-content p-0 m-0 body'>
					<Row className='p-0 m-0 pic-area'>
						<img
							src={detail.pic1 ? detail.pic1 : '/img/5.jpg'}
							style={{
								width: '100%',
								height: '300px',
								// position: 'absolute',
							}}
							alt='Your image'
						/>
					</Row>
					<Row className=' m-0 name-area'>
						<div className='p-3 w-100'>
							<h4
								className='mb-8'
								style={{
									fontWeight: '600',
									textTransform: 'capitalize',
								}}>
								{English ? detail.enname : detail.name}{' '}
							</h4>
							<Row className='w-100 mt-2'>
								<Col className='d-flex flex-row '>
									<div
										className=' d-flex flex-row'
										style={{
											paddingRight: '0',
											alignItems: 'center',
										}}>
										<p
											className='text-gray mb-0 '
											style={{
												// color: 'red',
												color: 'black',
												fontSize: '16px',
											}}>
											${parseFloat((detail.price1 / 100).toFixed(2))}
											{/* TODO:Login show different price */}
											{/* <del
												style={{
													color: 'gray',
												}}>
												${parseFloat((detail.price / 100).toFixed(2))}
											</del> */}
										</p>
									</div>
								</Col>
							</Row>
						</div>
					</Row>
					{detail.instock == 1 && (
						<>
							<div className='w-100 p-0 m-0 d-flex flex-column rules-area'>
								{detail?.rulename1 && JSON.parse(detail?.rulename1)?.cnname && (
									<>
										<Row
											className='w-100 p-3 m-0 d-flex flex-column'
											style={{
												backgroundColor: '#f8f9fa',
											}}>
											<h5
												className='m-0'
												style={{
													fontWeight: '600',
												}}>
												{' '}
												{/* Choice of Spicy */}
												{English
													? 'Choice of ' + JSON.parse(detail?.rulename1)?.enname
													: '选择' + JSON.parse(detail?.rulename1)?.cnname}
											</h5>
										</Row>
										<Row
											className='w-100 p-3 m-0 d-flex flex-row'
											style={{
												marginBottom: '100px',
											}}>
											{detail?.rule1?.replaceAll('，', ',')?.split(',').length > 0 &&
												detail?.rule1
													.replaceAll('，', ',')
													?.split(',')
													.map((i, index) => (
														<Col className='xs-3 p-0 pr-1 pl-1' key={i}>
															<Button
																value={i}
																active={i === firstValue}
																variant='outline-primary'
																// rootRef={refButton.current.focus()}
																className='mr-2 select-btn '
																onClick={(e) => setFirstValue(e.target.value)}>
																{i}
															</Button>
														</Col>
													))}
										</Row>
									</>
								)}
								{detail?.rulename2 && JSON.parse(detail?.rulename2)?.cnname && (
									<>
										<Row
											className='w-100 p-3 m-0 d-flex flex-column'
											style={{
												backgroundColor: '#f8f9fa',
											}}>
											<h5
												className='m-0'
												style={{
													fontWeight: '600',
												}}>
												{' '}
												{/* Choice of Spicy */}
												{English
													? 'Choice of ' + JSON.parse(detail?.rulename2)?.enname
													: '选择' + JSON.parse(detail?.rulename2)?.cnname}
											</h5>
										</Row>
										<Row
											className='w-100 p-3 m-0 d-flex flex-row'
											style={{
												marginBottom: '100px',
											}}>
											{detail?.rule2?.replaceAll('，', ',')?.split(',').length > 0 &&
												detail?.rule2
													.replaceAll('，', ',')
													?.split(',')
													.map((i, index) => (
														<Col className='xs-3 p-0 pr-1 pl-1' key={i}>
															<Button
																value={i}
																active={i === secondValue}
																variant='outline-primary'
																className='mr-2 select-btn'
																onClick={(e) => setSecondValue(e.target.value)}>
																{i}
															</Button>
														</Col>
													))}
										</Row>
									</>
								)}
								{detail?.rulename3 && JSON.parse(detail?.rulename3)?.cnname && (
									<>
										<div className='w-100 d-flex flex-column'>
											<Row
												className='w-100 p-3 m-0 d-flex flex-column'
												style={{
													backgroundColor: '#f8f9fa',
												}}>
												<h5
													className='m-0'
													style={{
														fontWeight: '600',
													}}>
													{' '}
													{/* Choice of Spicy */}
													{English
														? 'Choice of ' + JSON.parse(detail?.rulename3)?.enname
														: '选择' + JSON.parse(detail?.rulename3)?.cnname}
												</h5>
											</Row>
											<Row
												className='w-100 p-3 m-0 d-flex flex-row'
												style={{
													marginBottom: '100px',
												}}>
												{detail?.rule3?.replaceAll('，', ',')?.split(',').length > 0 &&
													detail?.rule3
														.replaceAll('，', ',')
														?.split(',')
														.map((i, index) => (
															<Col className='xs-3 p-0 pr-1 pl-1' key={i}>
																<Button
																	value={i}
																	active={i === thirdValue}
																	variant='outline-primary'
																	className='mr-2 select-btn'
																	onClick={(e) => setThirdValue(e.target.value)}>
																	{i}
																</Button>
															</Col>
														))}
											</Row>
										</div>
									</>
								)}
							</div>
						</>
					)}
				</Container>
			</DialogContent>
			<DialogActions className='p-0'>
				{detail.instock == 1 ? (
					<Row className='w-100 p-0 m-2 d-flex flex-column ' style={{ zIndex: '99' }}>
						<Col className='d-flex flex-row justify-content-center mb-2'>
							<FaMinusSquare
								fontSize='3em'
								color='#00BFFF'
								// style={{ zIndex: '99' }}
								onClick={() => minusCount(detail)}
							/>

							<div className=' d-flex align-items-center h-100'>
								<MuiButton
									className='text-center'
									sx={{
										minWidth: '37px',
										fontSize: '24px',
										maxHeight: '37px',
										color: '#000',
									}}>
									{count?.find((x) => x.id === detail.id)?.qty
										? count?.find((x) => x.id === detail.id)?.qty
										: 0}
								</MuiButton>
							</div>
							<FaPlusSquare fontSize='3em' color='#00BFFF' onClick={() => addCount(detail)} />
						</Col>
						<Col>
							<Button
								className='cart-btn w-100'
								onClick={() => {
									handleAddRules();
								}}>
								Add to Cart
							</Button>
						</Col>
					</Row>
				) : English ? (
					<Button className='cart-btn w-100 h-100 m-0 ' disabled>
						Out of stock
					</Button>
				) : (
					<Button className='cart-btn w-100 h-100 m-0 ' disabled>
						缺货
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default CourseDetail;
