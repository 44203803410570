import { Button, Col, Container, Image, Modal, Offcanvas, Row } from 'react-bootstrap';
import { FaMinusSquare, FaPlusSquare } from 'react-icons/fa';

import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { ImageViewer } from '../viewer/ImageViewer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	root: {
		position: 'relative',
		border: 'none',
		opacity: '0',
		height: '40px',
		// zIndex: '-10',
		width: '100%',
	},
	madol: {
		opacity: '0',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: '80px !important',
		// borderRadius: '10px',
		// opacity: '0',
	},
});
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});
const YouMayLike = ({ show, newArr, handleCheckout, cart, delItem, addItem, English, history, handleClose }) => {
	const firstCart = JSON.parse(localStorage.getItem('firstCart'));
	const len = firstCart?.length;

	const handleDetail = (item) => {
		localStorage.setItem('detial', JSON.stringify(item));
		history.push('../coursedetail');
	};
	return (
		<Dialog fullScreen open={show} TransitionComponent={Transition}>
			<AppBar sx={{ position: 'relative', backgroundColor: ' #fff', boxShadow: 'none' }}>
				<Toolbar>
					<IconButton edge='start' sx={{ color: 'gray' }} onClick={handleClose} aria-label='close'>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>

			<DialogContent sx={{ padding: '0' }}>
				<Typography sx={{ ml: 2, flex: 1 }} variant='h5' component='div'>
					{English ? 'You may also like' : '您可能也会喜欢的'}
				</Typography>
				{Array.isArray(newArr) &&
					newArr.length > 0 &&
					newArr.map((item, index) => (
						<Row className='p-3 m-0 w-100' key={item.id}>
							<Col
								md={12}
								xs={12}
								className='p-0'
								onClick={(e) => {
									handleDetail(item);
								}}>
								<div
									className='d-flex mb-2'
									style={{
										height: '150px',
										width: '100%',
									}}>
									<img
										src={item.pic1 ? item.pic1 : '/img/5.jpg'}
										style={{
											width: '100%',
											objectFit: 'cover',
										}}
										alt='Your image'
									/>
								</div>
							</Col>
							<Col style={{ borderBottom: '1px solid #d3d3d3' }}>
								<div className='flex-grow-1 ms-3 mb-2'>
									<Row className='w-100'>
										<Col className='d-flex flex-column'>
											<h6 className='mb-1'>{English ? item.enname : item.name} </h6>
											<div
												className=' d-flex flex-row'
												style={{
													paddingRight: '0',
												}}>
												<p
													className='text-gray mb-0'
													style={{
														// color: 'red',
														color: 'black',
													}}>
													${parseFloat((item.price1 / 100).toFixed(2))}
													{/* TODO:Login show different price */}
													{/* <del
														style={{
															color: 'gray',
														}}>
														${parseFloat((item.price / 100).toFixed(2))}
													</del> */}
												</p>
											</div>
										</Col>

										<Col
											className='d-flex flex-row justify-content-end'
											style={{
												// justifyContent:
												// 	'Right',
												padding: '0',
											}}>
											<Row
												className=' d-flex justify-content-end align-items-center'
												style={{
													// justifyContent:
													// 	'Right',
													padding: '0 5px 0 0',
												}}>
												{item.instock == 1 &&
													(item.rule1 ? (
														<>
															<button
																className='rule'
																name=''
																onClick={(e) => {
																	handleDetail(item);
																	// handleShow(item);
																	// history.push('../coursedetail');
																}}>
																<span>{English ? 'Customise' : '选规格'}</span>
															</button>
														</>
													) : (
														<>
															<FaMinusSquare
																fontSize='1.8em'
																color='#00BFFF'
																onClick={(e) => {
																	e.stopPropagation();
																	delItem(item);
																}}
															/>

															<div className=' d-flex align-items-center h-100'>
																<input
																	className='count-number-input  d-flex text-center '
																	type='text'
																	value={
																		cart.find((x) => x.id === item.id)
																			? cart.find((x) => x.id === item.id).qty
																			: 0
																	}
																	readOnly
																/>
															</div>
															<FaPlusSquare
																fontSize='1.8em'
																color='#00BFFF'
																onClick={(e) => {
																	e.stopPropagation();
																	addItem(item);
																}}
															/>
														</>
													))}
											</Row>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					))}
			</DialogContent>
			<DialogActions className='mb-4'>
				{len == cart.length ? (
					<Button className='w-100' onClick={handleCheckout}>
						{English ? 'No, thanks' : '不用了，谢谢'}
					</Button>
				) : (
					<Button className='w-100' onClick={handleCheckout}>
						{English ? 'continue' : '继续'}
					</Button>
				)}
			</DialogActions>
		</Dialog>
		// <Modal.Body>
		// 	{Array.isArray(newArr) &&
		// 		newArr.length > 0 &&
		// 		newArr.map((item, index) => (
		// 			<Row
		// 				className='pt-3 pb-3 pl-3 w-100'
		// 				style={{
		// 					padding: '0px',
		// 				}}
		// 				key={item.id}>
		// 				<Col
		// 					md={12}
		// 					xs={12}
		// 					style={{
		// 						paddingRight: '0px',
		// 					}}>
		// 					<div
		// 						className='d-flex'
		// 						style={{
		// 							height: '70px',
		// 						}}>
		// 						<div
		// 							className='flex-shrink-0 align-items-center justify-content-center mr-1'
		// 							style={{
		// 								width: '70px',
		// 							}}>
		// 							<ImageViewer className='w-100 h-100 view'>
		// 								<img
		// 									src={item.pic1 ? item.pic1 : '/img/5.jpg'}
		// 									className='w-100 h-100'
		// 									alt='Your image'
		// 								/>
		// 							</ImageViewer>
		// 						</div>
		// 						<div className='flex-grow-1 ms-3'>
		// 							<h6 className='mb-1'>{English ? item.enname : item.name} </h6>
		// 							<Row className='w-100'>
		// 								<Col className='d-flex flex-row '>
		// 									<div
		// 										className=' d-flex flex-row'
		// 										style={{
		// 											paddingRight: '0',
		// 										}}>
		// 										<p
		// 											className='text-gray mb-0'
		// 											style={{
		// 												color: 'red',
		// 											}}>
		// 											${parseFloat((item.price1 / 100).toFixed(2))}{' '}
		// 											<del
		// 												style={{
		// 													color: 'gray',
		// 												}}>
		// 												${parseFloat((item.price / 100).toFixed(2))}
		// 											</del>
		// 										</p>
		// 									</div>
		// 								</Col>

		// 								<Col
		// 									className='d-flex flex-row justify-content-end'
		// 									style={{
		// 										// justifyContent:
		// 										// 	'Right',
		// 										padding: '0',
		// 									}}>
		// 									<Row
		// 										className=' d-flex justify-content-end '
		// 										style={{
		// 											// justifyContent:
		// 											// 	'Right',
		// 											padding: '0 5px 0 0',
		// 										}}>
		// 										<FaMinusSquare
		// 											fontSize='1.8em'
		// 											color='#00BFFF'
		// 											onClick={() => delItem(item)}
		// 										/>

		// 										<div className=' d-flex align-items-center h-100'>
		// 											<input
		// 												className='count-number-input  d-flex text-center '
		// 												type='text'
		// 												value={
		// 													cart.find((x) => x.id === item.id)
		// 														? cart.find((x) => x.id === item.id).qty
		// 														: 0
		// 												}
		// 												readOnly
		// 											/>
		// 										</div>
		// 										<FaPlusSquare
		// 											fontSize='1.8em'
		// 											color='#00BFFF'
		// 											onClick={() => addItem(item)}
		// 										/>
		// 									</Row>
		// 								</Col>
		// 							</Row>
		// 						</div>
		// 					</div>
		// 				</Col>
		// 			</Row>
		// 		))}
		// 	<div>
		// 		<Row className='d-flex w-100 justify-content-center pl-4 mt-4 ' style={{ padding: '0' }}>
		// 			<Button className='w-75' onClick={handleCheckout}>
		// 				{English ? 'continue' : '继续'}
		// 			</Button>
		// 		</Row>
		// 		<Row className='d-flex w-100 justify-content-center mt-2 pl-4' style={{ padding: '0' }}>
		// 			<Button className='w-75' onClick={handleCheckout}>
		// 				{English ? 'No, thanks' : '不用了，谢谢'}
		// 			</Button>
		// 		</Row>
		// 	</div>
		// </Modal.Body>
	);
};

export default YouMayLike;
