import { Badge, Col, Container, Image, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

import CartDropdownHeader from '../cart/CartDropdownHeader';
import CartDropdownItem from '../cart/CartDropdownItem';
import DropDownTitle from '../common/DropDownTitle';
import Icofont from 'react-icofont';
import React from 'react';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isNavExpanded: false,
		};
	}
	setIsNavExpanded = (isNavExpanded) => {
		this.setState({ isNavExpanded: isNavExpanded });
	};
	closeMenu = () => {
		this.setState({ isNavExpanded: false });
	};

	handleClick = (e) => {
		if (this.node.contains(e.target)) {
			// if clicked inside menu do something
		} else {
			// If clicked outside menu, close the navbar.
			this.setState({ isNavExpanded: false });
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClick, false);
	}

	render() {
		return (
			<div ref={(node) => (this.node = node)}>
				<Navbar
					onToggle={this.setIsNavExpanded}
					expanded={this.state.isNavExpanded}
					color='light'
					expand='lg'
					className='navbar-light osahan-nav shadow-sm'>
					<Container>
						<Navbar.Brand to='/'>
							<Row>
								<Image src='/img/blue-logo.png' style={{ height: '30px' }} alt='' />
								<p className=' mb-0' style={{ fontWeight: '500' }}>
									Smart Order System - 智能点餐系统
									<br />
									Support@outz.net.au
								</p>
							</Row>
						</Navbar.Brand>
						{/* <Navbar.Toggle /> */}
						{/* <Navbar.Collapse id='navbarNavDropdown'>
							<Nav
								activeKey={0}
								className='ml-auto'
								onSelect={this.closeMenu}
							> */}
						{/* <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/">
			               Home <span className="sr-only">(current)</span>
			            </Nav.Link>
			            <Nav.Link eventKey={1} as={NavLink} activeclassname="active" to="/offers">
             				<Icofont icon='sale-discount'/> Offers <Badge variant="danger">New</Badge>
			            </Nav.Link> */}
						{/* <NavDropdown
									title='Restaurants'
									alignRight
									className='border-0'
								>
								
									<NavDropdown.Item
										eventKey={2.2}
										as={NavLink}
										activeclassname='active'
										to='/detail'
									>
										Detail + Cart
									</NavDropdown.Item>
									<NavDropdown.Item
										eventKey={2.3}
										as={NavLink}
										activeclassname='active'
										to='/checkout'
									>
										Checkout
									</NavDropdown.Item>
								</NavDropdown> */}
						{/* <NavDropdown title="Pages" alignRight>
			            	<NavDropdown.Item eventKey={3.1} as={NavLink} activeclassname="active" to="/track-order">Track Order</NavDropdown.Item>
			            	<NavDropdown.Item eventKey={3.2} as={NavLink} activeclassname="active" to="/invoice">Invoice</NavDropdown.Item>
			            	<NavDropdown.Item eventKey={3.3} as={NavLink} activeclassname="active" to="/login">Login</NavDropdown.Item>
			            	<NavDropdown.Item eventKey={3.4} as={NavLink} activeclassname="active" to="/register">Register</NavDropdown.Item>
			            	<NavDropdown.Item eventKey={3.5} as={NavLink} activeclassname="active" to="/404">404</NavDropdown.Item>
			            	<NavDropdown.Item eventKey={3.6} as={NavLink} activeclassname="active" to="/extra">Extra</NavDropdown.Item>

			            </NavDropdown> */}
						{/* </Nav>
						</Navbar.Collapse> */}
					</Container>
				</Navbar>
			</div>
		);
	}
}

export default Header;
